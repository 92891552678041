import React, { useEffect, useState } from 'react';
import HomePensionLogo from '../ui/logos/HomePensionLogo/HomePensionLogo';
import { Link } from 'react-router-dom';
import { Button, Dialog } from '@blueprintjs/core';
import './TopNav.css';
import useScrollDirection from '../../utils/useScrollDirection';

const TopNav = ({

}) => {

    const [isAtTop, setIsAtTop] = useState(true);
    const [openModal, toggleModal] = useState(false);
    const onOpenModal = () => toggleModal(true);
    const onCloseModal = () => toggleModal(false);

    const scrollDirection = useScrollDirection();

    const handleScroll = () => {
        if (window.scrollY > 0 && isAtTop === true) {
            setIsAtTop(false);
        }

        if (window.scrollY === 0 && isAtTop === false) {
            setIsAtTop(true);
        }

    }

    // useEffect(() => {
    //     window.addEventListener('scroll', handleScroll);
    //     return () => {
    //         window.removeEventListener('scroll', handleScroll);
    //     }
    // })

    return (
        <div className={'TopNavContainer atTop'}>
            <div className={'TopNav'}>
                <div className='TopNavContent'>
                    <Link to='/'
                        style={{
                            position: 'relative',
                            top: -2
                        }}
                    >
                        <HomePensionLogo width={188} />
                    </Link>
                    <nav>
                        {/* <Link to='/Calculator'>Calculator</Link> */}
                        <Link to='/faq'>FAQs</Link>
                        {/* <Link to='/Calculator'>Advisor Info</Link> */}
                        {/* <Button 
                            intent='primary'
                            text='Talk to your advisor'
                            onClick={onOpenModal}
                        /> */}
                    </nav>
                    <Dialog
                        isOpen={openModal}
                        onClose={onCloseModal}
                        transitionDuration={0}
                        className='OfferListModal'
                    >
                        <h2>Talk to your advisor today</h2>
                        <h3>Explore whether HomePension is right for you</h3>
                    </Dialog>
                </div>
            </div>
        </div>
    )
}

export default TopNav;