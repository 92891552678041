import React from 'react';
import './BarChart.css';

const BarChart = ({
    title,
    subheading,
    rangeYears = 30,
    primaryBarValues,
    secondaryBarValues,
    tertiaryBarValues,
    yAxisExtent = 1000000,
    primaryBarLegend,
    secondaryBarLegend,
    tertiaryBarLegend,
    heightMultiple = 160,
    age
}) => {

    let fullRangePrimaryValues = primaryBarValues;
    if (primaryBarValues.length < rangeYears) {
        const extraYears = rangeYears - primaryBarValues.length;
        const extraYearsArray = [...Array(Number(extraYears)).keys()].map(i => 0);
        fullRangePrimaryValues = [
            ...primaryBarValues,
            ...extraYearsArray
        ]
    }


    let fullRangeSecondaryValues = secondaryBarValues;
    if (secondaryBarValues && secondaryBarValues.length < rangeYears) {
        const extraYears = rangeYears - secondaryBarValues.length;
        const extraYearsArray = [...Array(Number(extraYears)).keys()].map(i => 0);
        fullRangeSecondaryValues = [
            ...secondaryBarValues,
            ...extraYearsArray
        ]
    }

    let fullRangeTertiaryValues = tertiaryBarValues;
    if (tertiaryBarValues && tertiaryBarValues.length < rangeYears) {
        const extraYears = rangeYears - tertiaryBarValues.length;
        const extraYearsArray = [...Array(Number(extraYears)).keys()].map(i => 0);
        fullRangeTertiaryValues = [
            ...tertiaryBarValues,
            ...extraYearsArray
        ]
    }

    const ages = [...Array(Number(fullRangePrimaryValues.length)).keys()].map(i => {
        const barWidth = 100 / rangeYears;
        return (
            <p 
                key={i}
                style={{
                    width: `${barWidth}%`
                }}
            >
               {age + i + 1 }
            </p>
        )
    });

    let primaryBars = fullRangePrimaryValues.map((barVal, i) => {
        const barHeight = barVal / yAxisExtent * heightMultiple;
        const barWidth = 100 / rangeYears;
        return (
            <div 
                className='bar primary' 
                style={{ 
                    height: barHeight,
                    width: `${barWidth}%`
                }} 
                key={i} 
            />
        )
    })

    let secondaryBars;
    let secondaryBarsOffset;
    if (fullRangeSecondaryValues) {

        secondaryBarsOffset = Math.max(...secondaryBarValues) / yAxisExtent * heightMultiple;

        secondaryBars = fullRangeSecondaryValues.map((barVal, i) => {
            const barHeight = barVal / yAxisExtent * heightMultiple;
            const barWidth = 100 / rangeYears;
            const barOffset = - (fullRangePrimaryValues[i]/yAxisExtent * heightMultiple) + barHeight
            return (
                <div 
                    className='bar secondary' 
                    style={{ 
                        height: barHeight,
                        width: `${barWidth}%`,
                        position: 'relative',
                        top: barOffset
                    }} 
                    key={i} 
                />
            )
        });
    }

    let tertiaryBars;
    let tertiaryBarsOffset;
    if (fullRangeTertiaryValues) {

        tertiaryBarsOffset = Math.max(...tertiaryBarValues) / yAxisExtent * heightMultiple;

        tertiaryBars = fullRangeTertiaryValues.map((barVal, i) => {
            const barHeight = barVal / yAxisExtent * heightMultiple;
            const barWidth = 100 / rangeYears;
            const barOffset = - (fullRangePrimaryValues[i]/yAxisExtent * heightMultiple) + barHeight
            return (
                <div 
                    className='bar tertiary' 
                    style={{ 
                        height: barHeight,
                        width: `${barWidth}%`,
                        position: 'relative',
                        top: barOffset - 1 
                    }} 
                    key={i} 
                />
            )
        });
    }

    const shortNumber = Intl.NumberFormat('en-US', {
        notation: "compact",
        maximumFractionDigits: 2
    })
    const topNumber = shortNumber.format(primaryBarValues[primaryBarValues.length - 1]);

    const barWidth = 100 / rangeYears;
    const barCount = primaryBarValues.length;
    const topNumberOffset = `calc(${barWidth*barCount}% - ${topNumber.toString().length === 2 ? 28 : 45}px)`;

    return (
        <div 
            className='BarChart'
        >
            <h2>{title}</h2>
            <h3 className='margin-top-0 margin-bottom-24'>{subheading}</h3>
            <div 
                className='PrimaryBars BarsContainer'
            >
                <div className='TopNumber'
                    style={{ left: topNumberOffset }}
                >
                ${topNumber}
            </div>
                {primaryBars}
            </div>
            <div 
                className='SecondaryBars BarsContainer'
                style={{
                    position: 'relative',
                    marginTop: -secondaryBarsOffset
                }}
            >
                {secondaryBars}
            </div>
            <div 
                className='TertiaryBars BarsContainer'
                style={{
                    position: 'relative',
                    marginTop: -tertiaryBarsOffset
                }}
            >
                {tertiaryBars}
            </div>
            {age && (
                <div className='Age'>
                    <span className='AgeLabel'>Age</span>
                    {ages}
                </div>
            )}
            <div className='margin-top-12'></div>
            {tertiaryBarLegend && (
                <div className='LegendItem'>
                    <div className='LegendIcon tertiary'></div>
                    {tertiaryBarLegend}
                </div>
            )}
             {secondaryBarLegend && (
                <div className='LegendItem'>
                    <div className='LegendIcon secondary'></div>
                    {secondaryBarLegend}
                </div>
            )}
            <div className='LegendItem'>
                <div className='LegendIcon'></div>
                {primaryBarLegend}
            </div>
           
  
        </div>
    )

}

export default BarChart;