import React from 'react';

const SunLifeLogo = ({
    width
}) => {

    const height = 31/127 * width;

    return (
        <svg 
            version="1.1" 
            id="Layer_1" xmlns="http://www.w3.org/2000/svg" 
            x="0px" 
            y="0px"
	        viewBox="0 0 127 31"
            width={width}
            height={height}
        >
            <g>
                <g>
                    <path 
                        fill='#003946' 
                        d="M46.89,14.42c-0.34-1.32-0.83-3.93-3.65-3.93c-1.47,0-2.59,0.98-2.59,2.59c0,3.98,8.27,3.72,8.27,9.26
                        c0,3.67-3.26,5.02-6.36,5.02c-2.25,0-3.72-0.59-4.55-1.03c-0.62-1.63-0.75-2.79-0.75-4.86l1.22-0.26
                        c0.36,1.55,1.21,5.17,4.52,5.17c1.63,0,2.71-1.14,2.71-2.87c0-4.37-8.01-4.03-8.01-9.33c0-3.28,2.43-4.65,5.76-4.65
                        c1.94,0,3.1,0.41,4.06,0.96c0.21,1.27,0.28,2.64,0.28,3.96H46.89z"/>
                    <path fill='#003946' d="M59.36,26.03c-0.78,0.47-1.99,1.32-3.93,1.32c-4.01,0-4.19-2.33-4.19-5.2V18.4c0-0.9,0-2.07-0.78-2.07
                        c-0.39,0-0.72,0.13-0.83,0.15v-0.75c1.94-0.65,3.36-0.85,4.71-0.93v7.26c0,2.04,0.39,3.77,2.92,3.77c0.98,0,1.58-0.47,1.94-0.65
                        v-6.8c0-0.9,0-2.07-0.78-2.07c-0.39,0-0.72,0.13-0.83,0.15v-0.75c1.94-0.65,3.36-0.85,4.71-0.93v7.73c0,2.12,0,3.13,0.88,3.13
                        c0.15,0,0.47,0.03,0.72-0.03l0.05,0.67c-1.19,0.41-2.61,0.78-3.9,1.03L59.36,26.03z"/>
                    <path fill='#003946' d="M66.44,18.4c0-0.9,0-2.07-0.78-2.07c-0.39,0-0.72,0.13-0.83,0.15v-0.75c1.94-0.65,3.31-0.85,4.65-0.93v1.5
                        c0.65-0.57,1.89-1.5,4.08-1.5c2.33,0,3.9,1.11,3.9,4.6v5.09c0,1.37,0.13,1.65,1.06,1.73l0.54,0.05v0.67h-6.31v-0.67l0.54-0.05
                        c0.93-0.08,1.06-0.36,1.06-1.73v-3.67c0-2.04,0-4.52-2.69-4.52c-1.06,0-1.73,0.54-2.15,0.98v7.21c0,1.37,0.13,1.65,1.06,1.73
                        l0.54,0.05v0.67h-6.26v-0.67l0.54-0.05c0.88-0.08,1.01-0.36,1.01-1.73V18.4z"/>
                    <path fill='#003946' d="M83.78,26.29l0.62-0.08c1.22-0.23,1.22-0.13,1.22-3.08v-9.49c0-2.51,0-2.82-1.22-3l-0.62-0.08V9.9h7.03v0.67
                        l-0.62,0.08c-1.21,0.18-1.21,0.49-1.21,3v11.97c0.78,0.18,1.58,0.36,2.38,0.36c3.65,0,3.67-1.76,4.42-3.9l0.93,0.18
                        c-0.16,1.68-0.34,3.05-0.9,4.7H83.78V26.29z"/>
                    <path fill='#003946' d="M97.31,26.29l0.54-0.05c0.93-0.1,1.06-0.36,1.06-1.73v-6.1c0-0.9,0-2.07-0.78-2.07
                        c-0.39,0-0.72,0.13-0.83,0.15v-0.75c1.94-0.65,3.36-0.85,4.71-0.93v9.69c0,1.37,0.13,1.63,1.06,1.73l0.54,0.05v0.67h-6.31V26.29z
                        M98.47,10.83c0-0.98,0.78-1.78,1.81-1.78c0.98,0,1.81,0.8,1.81,1.78c0,1.09-0.83,1.84-1.81,1.84
                        C99.25,12.66,98.47,11.89,98.47,10.83z"/>
                    <path fill='#003946' d="M106.23,15.2c0-3.59,1.78-7.24,5.84-7.24c1.37,0,2.25,0.41,2.82,0.85c-0.18,0.8-0.39,1.29-1.03,1.94h-0.18
                        c-0.49-0.47-1.68-1.5-2.69-1.5c-1.55,0-1.65,2.09-1.65,4.47v1.47c0.78,0.03,2.35,0.13,3.21,0.23v0.67
                        c-1.11,0.13-2.12,0.21-3.21,0.26v8.14c0,1.37,0.13,1.65,1.09,1.73l1.03,0.05v0.67h-6.82v-0.67l0.52-0.05
                        c0.96-0.1,1.09-0.36,1.09-1.73v-8.14V15.2z"/>
                    <path fill='#003946' d="M123.9,25.69c-0.67,0.54-2.3,1.65-4.91,1.65c-3.05,0-6.05-1.94-6.05-6.1c0-4.11,2.97-6.44,5.92-6.44
                        c4.16,0,4.83,2.71,5.12,4.21c-2.22,1.03-5.09,1.86-7.78,2.48c0.28,1.55,1.06,4.34,4.6,4.34c1.4,0,2.48-0.57,2.84-0.75L123.9,25.69
                        z M120.87,19.18c-0.18-1.86-0.96-3.39-2.59-3.39c-2.25,0-2.25,4.03-2.25,4.5C117.67,20.03,119.3,19.69,120.87,19.18z"/>
                </g>
                <g>
                    <path fill='#EAAB00' d="M16.85,3.6c-0.05-0.44-0.07-0.9-0.03-1.35c0.02-0.21,0.06-0.43,0.11-0.64c0.01-0.05,0.03-0.14,0.05-0.24
                        c0.02-0.09-0.07-0.15-0.14-0.1c-0.61,0.5-1.15,1.17-1.55,1.85c-0.33,0.56-0.57,1.2-0.63,1.86c0.82,0.1,1.62,0.27,2.41,0.52
                        C17.1,4.86,16.92,4.23,16.85,3.6z"/>
                    <path fill='#EAAB00' d="M11.07,5.05c0.73-0.12,1.47-0.17,2.2-0.16c-0.02-0.1-0.04-0.2-0.06-0.29c-0.08-0.31-0.27-0.6-0.4-0.89
                        c-0.15-0.32-0.28-0.66-0.38-1c-0.08-0.26-0.14-0.52-0.17-0.79c-0.02-0.15-0.02-0.3-0.02-0.45c0-0.03,0-0.08,0-0.14
                        c0-0.08-0.09-0.12-0.14-0.06c-0.38,0.5-0.64,1.1-0.84,1.71C11.12,3.41,11.01,3.85,11,4.3c-0.01,0.2,0,0.4,0.04,0.59
                        C11.04,4.94,11.05,5,11.07,5.05z"/>
                    <path fill='#EAAB00' d="M7.56,5.98C7.58,6.02,7.6,6.06,7.63,6.1C7.7,6.07,7.77,6.03,7.84,6c0.53-0.24,1.08-0.43,1.62-0.59
                        c-0.03-0.07-0.07-0.14-0.11-0.2C9.21,4.96,8.98,4.76,8.8,4.54C8.59,4.3,8.4,4.05,8.23,3.78C8.11,3.58,7.99,3.36,7.9,3.14
                        C7.85,3.02,7.81,2.89,7.78,2.76C7.77,2.74,7.75,2.69,7.74,2.64C7.72,2.57,7.63,2.56,7.61,2.63C7.4,3.14,7.32,3.72,7.3,4.29
                        c-0.01,0.39,0,0.8,0.09,1.18C7.43,5.65,7.48,5.82,7.56,5.98z"/>
                    <path fill='#EAAB00' d="M21.25,7.73c0.76,0.61,1.46,1.3,2.08,2.08c0.46-0.61,0.71-1.35,1.06-2.02c0.25-0.47,0.54-0.94,0.89-1.35
                        c0.17-0.2,0.34-0.39,0.54-0.56c0.04-0.04,0.13-0.12,0.22-0.2c0.08-0.07,0.03-0.2-0.07-0.19c-0.94,0.08-1.93,0.38-2.79,0.78
                        C22.44,6.62,21.76,7.11,21.25,7.73z"/>
                    <path fill='#EAAB00' d="M25.07,12.69c0.07,0.15,0.13,0.31,0.19,0.46c0.69-0.52,1.16-1.25,1.73-1.86c0.4-0.43,0.82-0.83,1.3-1.17
                        c0.23-0.16,0.47-0.31,0.72-0.44c0.06-0.03,0.17-0.09,0.28-0.15c0.1-0.05,0.09-0.2-0.02-0.22c-1.01-0.17-2.13-0.14-3.14,0.05
                        c-0.85,0.15-1.69,0.47-2.4,0.98C24.24,11.06,24.7,11.85,25.07,12.69z"/>
                    <path fill='#EAAB00' d="M20.94,5.13c0.1-0.48,0.23-0.96,0.42-1.42c0.09-0.22,0.2-0.43,0.33-0.63c0.03-0.05,0.08-0.14,0.14-0.23
                        c0.05-0.08-0.02-0.18-0.11-0.15c-0.8,0.32-1.6,0.84-2.25,1.42c-0.55,0.49-1.03,1.1-1.31,1.79c0.83,0.35,1.63,0.78,2.37,1.29
                        C20.78,6.54,20.8,5.81,20.94,5.13z"/>
                    <path fill='#EAAB00' d="M28.33,17.91c-0.68-0.27-1.43-0.43-2.16-0.38c0.03,0.91-0.04,1.82-0.19,2.71c0.73-0.06,1.41-0.37,2.1-0.56
                        c0.47-0.13,0.96-0.23,1.46-0.25c0.24-0.01,0.47-0.01,0.71,0.01c0.05,0.01,0.16,0.01,0.27,0.02c0.1,0.01,0.15-0.1,0.08-0.16
                        C29.95,18.72,29.14,18.24,28.33,17.91z"/>
                    <path fill='#EAAB00' d="M30.68,14.27c-0.85-0.41-1.85-0.67-2.79-0.77c-0.8-0.08-1.64-0.02-2.41,0.26c0.32,0.95,0.53,1.92,0.63,2.89
                        c0.75-0.28,1.37-0.81,2.04-1.22c0.46-0.28,0.94-0.52,1.46-0.7c0.24-0.08,0.49-0.15,0.75-0.2c0.06-0.01,0.17-0.04,0.29-0.06
                        C30.75,14.44,30.78,14.31,30.68,14.27z"/>
                    <path fill='#EAAB00' d="M21.71,27.8c-0.49,0.43-1.01,0.82-1.57,1.18c0.37,0.37,0.85,0.6,1.27,0.89c0.29,0.21,0.57,0.44,0.82,0.7
                        c0.12,0.13,0.23,0.26,0.32,0.4c0.02,0.03,0.07,0.09,0.11,0.16c0.04,0.06,0.13,0.04,0.13-0.03c0.02-0.63-0.08-1.31-0.28-1.91
                        C22.35,28.68,22.09,28.19,21.71,27.8z"/>
                    <path fill='#EAAB00' d="M24.2,24.87c-0.39,0.63-0.84,1.22-1.33,1.78c0.53,0.28,1.12,0.36,1.68,0.55c0.38,0.12,0.76,0.27,1.11,0.48
                        c0.17,0.1,0.33,0.2,0.48,0.32c0.03,0.03,0.1,0.08,0.18,0.13c0.06,0.05,0.15,0,0.13-0.08c-0.18-0.68-0.53-1.38-0.94-1.96
                        C25.16,25.61,24.72,25.17,24.2,24.87z"/>
                    <path fill='#EAAB00' d="M27.51,22.24c-0.52-0.41-1.13-0.75-1.78-0.9c-0.21,0.8-0.5,1.57-0.86,2.31c0.65,0.14,1.31,0.04,1.95,0.06
                        c0.45,0.01,0.9,0.06,1.34,0.17c0.21,0.05,0.42,0.12,0.62,0.2c0.05,0.02,0.14,0.05,0.23,0.09c0.08,0.03,0.16-0.05,0.12-0.12
                        C28.71,23.37,28.12,22.74,27.51,22.24z"/>
                    <g>
                        <path fill='#EAAB00' d="M10.59,28.54c-2.11,0.26-4.06,0.06-5.62-0.57c0.69,0.56,1.43,1.05,2.22,1.46c1.89,0.98,4.04,1.51,6.31,1.45
                            c0.97-0.03,1.91-0.16,2.81-0.38c-1.52-0.17-2.98-0.59-4.33-1.21C11.51,29.06,11.04,28.81,10.59,28.54z"/>
                        <path fill='#EAAB00' d="M21.97,23.19c0,0,0.06-0.09,0-0.16c-0.06-0.06-0.15,0.01-0.15,0.01c-1.26,1.24-2.79,2.38-4.55,3.32
                            c-0.33,0.18-0.67,0.35-1.01,0.5c-1.47,0.69-2.95,1.18-4.38,1.47c0.42,0.24,0.86,0.46,1.31,0.66c1.33,0.59,2.76,0.98,4.25,1.17
                            c0.59-0.21,1.16-0.45,1.71-0.74c-0.8-0.6-1.55-1.25-2.26-1.93C19.07,26.31,20.82,24.79,21.97,23.19z"/>
                        <path fill='#EAAB00' d="M1.61,20.76c0.1,0.05,0.2,0.11,0.31,0.16c0.39,0.18,0.83,0.32,1.31,0.42c0.4,0.09,0.83,0.14,1.29,0.17
                            c-0.19-0.44-0.35-0.89-0.5-1.35c-0.22-0.71-0.4-1.44-0.51-2.19c-0.04-0.25-0.07-0.5-0.1-0.75c-0.02-0.21-0.04-0.42-0.05-0.64
                            c-0.01-0.11-0.01-0.22-0.01-0.34c-0.02-0.43-0.02-0.86,0-1.28c-0.51-0.07-0.95-0.2-1.31-0.39c-0.05-0.02-0.09-0.05-0.13-0.08
                            c-0.23-0.14-0.43-0.3-0.58-0.49c-0.08-0.1-0.15-0.21-0.21-0.33c-0.05-0.11-0.09-0.22-0.12-0.34c-0.19,0.51-0.35,1.04-0.48,1.58
                            c-0.05,0.21-0.1,0.43-0.13,0.65c-0.08,0.44-0.14,0.88-0.17,1.33c-0.01,0.11-0.01,0.22-0.02,0.32c-0.02,0.33-0.02,0.66-0.02,1
                            c0.01,0.43,0.04,0.86,0.1,1.28C0.58,20.03,1.04,20.44,1.61,20.76z"/>
                        <path fill='#EAAB00' d="M5.4,14.97C5.06,15,4.74,15.02,4.43,15.02c0,0.3,0.01,0.6,0.02,0.91c0,0,0,0.01,0,0.01
                            c0.01,0.1,0.02,0.2,0.02,0.3c0.01,0.17,0.02,0.34,0.04,0.51c0.08,0.81,0.22,1.59,0.41,2.34c0.21,0.84,0.49,1.65,0.82,2.42
                            c1.72-0.07,3.69-0.49,5.72-1.26c-0.81-1.47-1.49-2.94-2.07-4.36c-0.1-0.25-0.2-0.51-0.3-0.76c0.38-0.15,0.75-0.31,1.13-0.49
                            c2.37-1.13,4.26-2.6,5.34-4.04c0.07-0.1,0.13-0.19,0.2-0.29c0,0,0.07-0.1-0.01-0.18c-0.07-0.07-0.17,0-0.17,0
                            c-0.21,0.2-0.42,0.4-0.66,0.6c-1.09,0.93-2.48,1.82-4.07,2.57c-0.72,0.34-1.44,0.62-2.14,0.86c-0.7,0.24-1.38,0.43-2.03,0.57
                            C6.26,14.85,5.82,14.92,5.4,14.97z"/>
                        <path fill='#EAAB00' d="M1.98,13.19c0.19,0.18,0.43,0.33,0.72,0.44c0.08,0.03,0.17,0.06,0.25,0.09c0.15,0.05,0.32,0.09,0.49,0.12
                            c0.02-0.2,0.05-0.4,0.08-0.59c0.03-0.16,0.05-0.31,0.08-0.47c0.1-0.52,0.23-1.03,0.38-1.53c0.32-1.07,0.74-2.09,1.27-3.06
                            C5.39,7.91,5.54,7.64,5.7,7.39c0.05-0.07,0.09-0.14,0.14-0.22C5.45,7.43,5.08,7.72,4.73,8.02c-0.54,0.46-1.04,0.97-1.5,1.51
                            c-0.69,0.82-1.28,1.72-1.75,2.7c0.02,0.16,0.06,0.32,0.13,0.47C1.69,12.88,1.82,13.04,1.98,13.19z"/>
                        <path fill='#EAAB00' d="M4.74,11.83c-0.04,0.19-0.07,0.39-0.1,0.58c-0.08,0.5-0.14,1-0.17,1.51c0.74,0.01,1.58-0.08,2.49-0.28
                            c0.47-0.1,0.96-0.24,1.46-0.39c-0.06-0.17-0.11-0.34-0.17-0.5c-0.24-0.72-0.44-1.4-0.62-2.04c-0.06-0.22-0.12-0.44-0.18-0.65
                            c-0.1-0.36-0.18-0.7-0.26-1.02C7.03,8.4,6.9,7.84,6.81,7.37C6.77,7.17,6.73,6.98,6.7,6.82C6.69,6.78,6.68,6.74,6.68,6.7
                            C6.64,6.76,6.6,6.83,6.56,6.89c-0.15,0.26-0.28,0.53-0.42,0.8C5.72,8.55,5.37,9.44,5.1,10.37C4.96,10.85,4.84,11.34,4.74,11.83z"
                            />
                        <path fill='#EAAB00' d="M9.33,27.68c-1.39-1.05-2.6-2.35-3.55-3.82c-0.24-0.37-0.47-0.76-0.68-1.15c-0.88-0.01-1.68-0.11-2.38-0.31
                            c-0.38-0.11-0.73-0.25-1.05-0.41c-0.1-0.05-0.19-0.11-0.28-0.16c-0.3-0.19-0.57-0.41-0.79-0.66c0.16,0.62,0.37,1.23,0.62,1.81
                            c0.04,0.09,0.08,0.19,0.12,0.28c0.14,0.32,0.3,0.63,0.47,0.93c0.15,0.27,0.31,0.53,0.47,0.79c0.29,0.44,0.6,0.86,0.94,1.26
                            C4.61,27.37,6.8,27.86,9.33,27.68z"/>
                        <path fill='#EAAB00' d="M13.88,20.43c-0.61,0.3-1.23,0.57-1.84,0.81c-2.02,0.8-3.98,1.27-5.72,1.42c0.21,0.38,0.43,0.74,0.66,1.1
                            c0.99,1.49,2.22,2.76,3.64,3.77c1.57-0.24,3.25-0.72,4.93-1.44c-1.11-1.24-2.08-2.57-2.95-3.93c0.34-0.15,0.68-0.31,1.02-0.48
                            c3.11-1.55,5.5-3.62,6.81-5.67c0,0,0.07-0.1-0.01-0.17c-0.08-0.08-0.17,0-0.17,0C18.69,17.53,16.49,19.15,13.88,20.43z"/>
                    </g>
                </g>
            </g>
        </svg>
    )

}

export default SunLifeLogo;