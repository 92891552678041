import React from 'react';

const DesjardinsLogo = ({
    width
}) => {

    const height = 32/150 * width;

    return (
        <svg 
            version="1.1" 
            id="logo-n1-desjardins-desktop" 
            xmlns="http://www.w3.org/2000/svg" 
            x="0px" y="0px" width={width} height={height} 
            viewBox="0 0 150 32" 
            fill='#00874E'
        >
            <g>
                <path class="st0" d="M43.2,7.1l-6.1,0v17.8h6.2c5.6,0,9.2-3.5,9.2-8.9C52.5,10.7,48.7,7.1,43.2,7.1z M43.1,21.9h-2.3V10.2h2.5
                    c3.3,0,5.6,2.5,5.6,5.9C48.9,19.5,46.5,21.9,43.1,21.9z"/>
                <path class="st0" d="M0,8v16l13.9,8l13.9-8V8L13.9,0L0,8z M24,10.2v11.7l-10.1,5.8L3.8,21.8V10.2l10.1-5.8L24,10.2z"/>
                <path class="st0" d="M123.4,8.7c0,1-0.8,1.9-1.9,1.9c-1,0-1.9-0.8-1.9-1.9c0-1,0.8-1.9,1.9-1.9C122.5,6.8,123.4,7.7,123.4,8.7z
                    M123.1,12.7h-3.2v12.2h3.2V12.7z"/>
                <path class="st0" d="M72.9,17.4c-1.4-0.5-1.8-0.8-1.8-1.4c0-0.5,0.4-0.9,1.2-0.9c1.2,0,2.1,0.7,2.9,1.4l1.8-2c-1.2-1.3-2.9-2-4.7-2
                    c-2.6,0-4.4,1.5-4.4,3.6c0,2.4,1.9,3.4,3.7,4c0.2,0.1,0.4,0.1,0.6,0.2c1,0.3,1.6,0.5,1.6,1.1c0,0.4-0.2,0.9-1.5,0.9
                    c-1.2,0-2.4-0.6-3-1.5l-1.9,2c1.3,1.5,2.9,2.2,4.9,2.2c2.9,0,4.7-1.4,4.7-3.7C77.1,19.1,75.5,18.4,72.9,17.4z"/>
                <path class="st0" d="M144.2,17.4c-1.4-0.5-1.8-0.8-1.8-1.4c0-0.5,0.4-0.9,1.2-0.9c1.2,0,2.1,0.7,2.9,1.4l1.8-2
                    c-1.2-1.3-2.9-2-4.7-2c-2.6,0-4.4,1.5-4.4,3.6c0,2.4,1.9,3.4,3.7,4c0.2,0.1,0.4,0.1,0.6,0.2c1,0.3,1.6,0.5,1.6,1.1
                    c0,0.4-0.2,0.9-1.5,0.9c-1.2,0-2.4-0.6-3-1.5l-1.9,2c1.3,1.5,2.9,2.2,4.9,2.2c2.9,0,4.7-1.4,4.7-3.7
                    C148.4,19.1,146.8,18.4,144.2,17.4z"/>
                <path class="st0" d="M82.8,8.7c0,1-0.8,1.9-1.9,1.9c-1,0-1.9-0.8-1.9-1.9s0.8-1.9,1.9-1.9C81.9,6.8,82.8,7.7,82.8,8.7z M79,29.6
                    c2.1,0,3.5-1.4,3.5-4.3V12.7h-3.2v12.5c0,1.1-0.5,1.7-1.2,1.7c-0.4,0-0.7-0.1-1.1-0.3l-0.5,2.7C77.3,29.5,77.9,29.6,79,29.6
                    L79,29.6z"/>
                <path class="st0" d="M92.8,13.4c-0.9-0.6-2-0.9-3.3-0.9c-2,0-3.6,0.7-4.6,2c0.2,0.2,1.6,1.7,1.9,2c0.5-0.7,1-1.1,1.7-1.3
                    c0.3-0.1,0.5-0.1,0.8-0.1c0.4,0,0.8,0.1,1.1,0.2c0.8,0.3,1.2,0.9,1.2,1.7l0,0.8c-0.1,0-1.1-0.5-2.7-0.5c-2.6,0-4.5,1.7-4.5,3.9
                    c0,2.2,1.7,3.8,4,3.8c1.4,0,2.3-0.6,3.1-1.3h0.1v1.1h3v-8C94.6,15.3,94,14.2,92.8,13.4z M91.6,20.9c0,0.6-1.3,1.6-2.8,1.6
                    c-0.9,0-1.6-0.6-1.6-1.4c0-0.5,0.3-1.5,1.9-1.5c1.2,0,2.5,0.4,2.5,0.4V20.9z"/>
                <path class="st0" d="M100.5,14.1L100.5,14.1l-0.1-1.4h-3.2v12.2h3.2l0-5.5c0-2.2,1.1-3.7,3.8-3.7v-3.2
                    C102.6,12.5,101.3,13,100.5,14.1z"/>
                <path class="st0" d="M114.2,23.8h0.1v1.1h3l0-17.8h-3.2v6.6H114c-0.6-0.8-2.1-1.2-3.1-1.2c-3.9,0-5.9,3.2-5.9,6.3
                    c0,2.3,1,3.8,1.8,4.6c1.1,1.1,2.6,1.7,4.1,1.7C111.6,25.1,112.8,24.9,114.2,23.8z M111.1,22.2c-1.7,0-3.1-1.5-3.1-3.4
                    c0-1.9,1.4-3.4,3.1-3.4c1.9,0,3.1,1.8,3.1,3.4C114.2,20.7,112.8,22.2,111.1,22.2z"/>
                <path class="st0" d="M129,13.7h-0.1v-1h-3.2v12.2h3.2v-6.3c0-2.8,1.7-3.2,2.8-3.2c1.8,0,2.5,1.5,2.5,2.9v6.6h3.2v-6.6
                    c0-3.6-2-5.8-5.2-5.8C129.9,12.5,129,13.7,129,13.7z"/>
                <path class="st0" d="M66.2,18.7c0-3.6-2.5-6.2-6-6.2c-3.5,0-6,2.6-6,6.3c0,3.7,2.6,6.3,6.3,6.3c2.2,0,4-0.8,5.2-2.2
                    c-0.2-0.3-1.6-1.7-1.9-2c-0.6,0.7-1.7,1.4-3,1.4c-1.6,0-2.8-0.9-3.2-2.5h8.6C66.2,19.6,66.2,19.1,66.2,18.7z M57.5,17.5
                    c0.4-1.5,1.4-2.3,2.8-2.3c1.4,0,2.4,0.8,2.7,2.3H57.5z"/>
            </g>
        </svg>

    )
}

export default DesjardinsLogo;