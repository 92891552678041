import React, { useEffect, useState } from 'react';

import './HomePage.css';
import { Button, Callout, Divider, FormGroup, Icon, InputGroup, NumericInput, Tooltip } from '@blueprintjs/core';
import { Link, useNavigate } from 'react-router-dom';

import HeroImage from './images/couple-1.png';
import GuaranteedImage from './images/granddad-2.png';
import NoRiskImage from './images/newspaper-3.png';
import TaxImage from './images/grandmother-1.png';
import accounting from 'accounting-js';
import ReactGA from 'react-ga4';

import KeyPointIcon from '../ui/KeyPointIcon/KeyPointIcon';
import { useLazyQuery, useQuery } from '@apollo/client';
import QUOTE from './queries/quote';
import Footer from '../ui/Footer/Footer';
import { PopupModal } from 'react-calendly';
import PositionedIcon from '../ui/PositionedIcon/PositionedIcon';
import { setLocalToken } from '../../utils/authUtils';
import GET_TOKEN from '../Calculator/queries/getToken';
import YouTubePlayer from 'react-youtube';
import LiteYouTubeEmbed from 'react-lite-youtube-embed';
import YoutubeEmbed from './YoutubeEmbed/YoutubeEmbed';

const rmTooltipContent = `
    A reverse mortgage is a loan secured against the value of your home. It lets you unlock the value in your home without having to sell or move away. The money you receive is tax-free and yours to use as you wish. The loan balance is repaid when you decide to sell your home.
`

const annuityTooltipContent = `
    A life annuity provides guaranteed income payments for as long as you live. A joint life annuity provides payments as long as you or your spouse/partner lives. You have the option to choose a guaranteed period. If you die before the end of that period, your beneficiary will receive the balance of the guaranteed income payments.
`

const HomePage = ({

}) => {

    const navigate = useNavigate();

    const [age, setAge] = useState(null);
    const [postCode, setPostCode] = useState('');
    const [city, setCity] = useState('');
    const onSetCity = (e) => {
        setCity(e.target.value);
    }

    const { loading: loadingToken, error: errorToken, data: tokenData } = useQuery(GET_TOKEN, {
        onCompleted: (response) => {
            console.log('TOKEN', response);
            setLocalToken(response.getToken)
        }
    });

    const onSetPostCode = (e) => {
        setPostCode(e.target.value.toUpperCase().split(' ').join(''));
    }

    const onChangeAge = (e) => {
        let val = e.target.value
        if (isNaN(val)) {
            return;
        }
        if (val.toString().includes('e')) {
            val = val.replace('e', '');
        }
        return e.target.value === '' ? setAge('') : setAge(parseInt(e.target.value));
    }

    useEffect(() => {
        ReactGA.send({ 
            hitType: "pageview", 
            page: `${window.location.pathname+window.location.search}`, 
            title: "Home" 
        });
    }, [])

    const [calendlyOpen, toggleCalendly] = useState(false);
    const onOpenCalendly = () => {
        ReactGA.event('HP_open_scheduler', {
            age: age,
            homeValue: homeVal
        })
        toggleCalendly(true);
    }
    const onCloseCalendly = () => toggleCalendly(false);

    const [homeVal, setHomeVal] = useState(null);
    const [homeValString, setHomeValString] = useState('');
    const onSetHomeVal = (e) => {

        const int = accounting.unformat(e.target.value);
        const newString = accounting.formatMoney(int, { precision: 0 });

        if (int === 0) {
            setHomeValString('');
            setHomeVal(int);
        }

        else {
            setHomeVal(int);
            setHomeValString(newString);
        }

    }

    let isDisabled = true;
    if (age && age >= 55 && homeVal >= 250000 && postCode.length===6) {
        isDisabled = false;
    }



    const ageError = age && age < 55 ? true : false;
    const homeValError = homeVal && homeVal < 250000 ? true : false; 

    const [apiError, setAPIerror] = useState(false);

    const [getQuote, { loading, error, data }] = useLazyQuery(QUOTE, {
        variables: {
            age1: age,
            homeVal: Number(homeVal),
            postCode: postCode,
            city: city,
            attributionSource: window.localStorage.getItem('utm_source')
        },
        onCompleted: (data) => {
            console.log('DATA', data);
            
            // handle API errors
            if (data && (data.quote.error || !data.quote.annuityOffers || data.quote.annuityOffers.length === 0)) {
                setAPIerror(true);
            }
            if (data && data.quote.annuityOffers && data.quote.annuityOffers.length > 0) {
                navigate(`/offer?m=${data.quote.annuityOffers[0].monthlyIncome}&rm=${data.quote.chipQuoteMax}&a=${age}&h=${homeVal}&pc=${postCode}`);
            }
        }
    });

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        })
    }

    const onSubmit = () => {
        ReactGA.event('HP_getQuote', {
            age: age,
            homeValue: homeVal
        })
        getQuote();
    }

    return (
        <div className='HomePage page'>
            <div className='HomePageSection AboveFold'>
                <div className='MobileVideoContainer'>
                    <YoutubeEmbed />
                </div>
                <div className='TextContentContainer'>
                    <div className='TextContent margin-bottom-4'>
                        <h1>Get <span className='secondary-accent'>guaranteed lifetime income</span> from the value in your home
                        </h1>
                        <Divider />
                        <h3>Find out how much monthly income you could qualify for</h3>
                        <form onSubmit={onSubmit}>
                            <div className='InputRow'>
                                <FormGroup
                                    label='Your age'
                                    labelFor='age'
                                    className='AgeInput'
                                    fill
                                >
                                    <InputGroup
                                        id='age'
                                        large
                                        value={age}
                                        onChange={onChangeAge}
                                        buttonPosition='none'
                                        fill
                                        inputMode='numeric'
                                        max={100}
                                        min={55}
                                        intent={age && age < 55 ? 'danger' : 'none'}
                                    />
                                </FormGroup>
                                <FormGroup
                                    label='Your home value (estimated)'
                                    labelFor='homeVal'
                                    className='HomeValInput'
                                    fill
                                >
                                    <InputGroup
                                        fill
                                        id='homeVal'
                                        large
                                        value={homeValString}
                                        onChange={onSetHomeVal}
                                        intent={homeVal && homeVal < 250000 ? 'danger' : 'none'}
                                    />
                                </FormGroup>
                            </div>
                            <div className='InputRow margin-top-8 margin-bottom-8'>
                                <FormGroup
                                    label='Postal Code'
                                    labelFor='postal-code'
                                    className='PostalCodeInput'
                                    fill
                                >
                                    <InputGroup
                                        fill
                                        id='postal-code'
                                        large
                                        value={postCode}
                                        onChange={onSetPostCode}
                                    />
                                </FormGroup>
                                <FormGroup
                                    label='City'
                                    labelFor='city'
                                    className='PostalCodeInput'
                                    fill
                                >
                                    <InputGroup
                                        fill
                                        id='city'
                                        large
                                        value={city}
                                        onChange={onSetCity}
                                    />
                                </FormGroup>
                            </div>
                            {isDisabled && (ageError || homeValError) && (
                                <div className='margin-bottom-8 FormErrors'>
                                    <Callout>
                                        {age && age < 55 && <p>HomePension is only for Canadians 55+</p>}
                                        {homeVal && homeVal < 250000 && <p>HomePension is only available for homes worth $250,000 or more</p>}
                                    </Callout>
                                </div>  
                            )}
                            <Button 
                                intent='primary'
                                text='See how much you could get'
                                className='CalculateButton'
                                disabled={isDisabled}
                                fill
                                type='submit' value={'submit'}
                                onClick={onSubmit}
                                loading={loading}
                            />
                            <div className='margin-top-16'>
                                <p>Or <span className='LinkBtn' onClick={onOpenCalendly}>request a call to learn more</span></p>
                            </div>
                            {apiError && (
                                <div className='margin-top-8 FormErrors'>
                                    <Callout>
                                        <p>Sorry, something went wrong. <br/>Please try again later.</p>
                                    </Callout>
                                </div>  
                            )}
                        </form>
                    </div>
                </div>
                <div className='HeroImageContainer'>
                    <YoutubeEmbed />
                </div>
            </div>
            <div className='margin-bottom-64'>
                <Divider />
            </div>
            <div className='HomePageSummary'>
                <Callout>
                    <p>HomePension provides guaranteed lifetime monthly income by combining the <Tooltip
                        minimal
                        placement='bottom'
                        popoverClassName='RMtooltip'
                        content={(
                            <div>
                                <p>{rmTooltipContent}</p>
                            </div>
                        )}
                    >
                        <span className='TooltipTarget'> CHIP Reverse Mortgage <PositionedIcon 
                            icon='info-sign'
                            color='#999'
                            top={-8} 
                            size={18}
                        /></span>
                    </Tooltip> and a <Tooltip
                        minimal
                        placement='bottom'
                        popoverClassName='RMtooltip'
                        content={(
                            <div>
                                <p>{annuityTooltipContent}</p>
                            </div>
                        )}
                    >
                        <span className='TooltipTarget'> life annuity <PositionedIcon 
                            icon='info-sign'
                            color='#999'
                            top={-8} 
                            size={18}
                        /></span>
                    </Tooltip></p>
                    <p>You have the flexibility to select the annuity options that suit your needs, and a wealth professional can assist you in making your choice.</p>
                    <div className='center margin-top-24'>
                        <Link to='/faq'>
                            <Button    
                                intent='primary'
                                text='Learn more'
                            />
                        </Link>
                    </div>
                </Callout>
            </div>
            <div className='ValueProps HomePageSectionContent'>
                <div className='ValueProp'>
                    <img src={GuaranteedImage} />
                    <div className='ValuePropText'>
                        <h3 className='margin-bottom-0 margin-top-8'>
                            Guaranteed for Life
                        </h3>
                        <p className='margin-top-0'>
                            Receive guaranteed monthly income for the rest of your life, even after you sell your home.
                        </p>
                    </div>
                </div>
                <div className='ValueProp'>
                    <img src={NoRiskImage} />
                    <div className='ValuePropText'>
                        <h3 className='margin-bottom-0 margin-top-8'>
                            No Market Risk
                        </h3>
                        <p>
                            Be protected from market volatility through a guaranteed, worry-free stream of income throughout retirement. 
                        </p>
                    </div>
                </div>
                <div className='ValueProp'>
                    <img src={TaxImage} />
                    <div className='ValuePropText'>
                        <h3 className='margin-bottom-0 margin-top-8'>
                            Tax Efficient
                        </h3>
                        <p className='margin-top-0'>
                            Eligibility for reverse mortgage interest tax deductibility means you take home tax-free income at the end of the day*
                        </p>
                        <p className='text-xs'>*Conditions apply, refer to a financial advisor for your tax implications</p>
                    </div>
                </div>
            </div>
            <div className='margin-top-128 margin-bottom-128 HomePageSectionContent'>
                <Divider />
                <div className='center margin-top-104 margin-bottom-104'>
                    <h1 className='font-500'>Enjoy your retirement with financial peace of mind</h1>
                    <Button
                        className='homepage-btn center'
                        intent='primary'
                        text='See how much you could get'
                        onClick={scrollToTop}
                    />
                    <div className='margin-top-8'>
                        <Button
                            className='homepage-btn center'
                            text='Request a call to learn more'
                            onClick={onOpenCalendly}
                        />
                    </div>
                </div>
            </div>
            <PopupModal
                open={calendlyOpen}
                onModalClose={onCloseCalendly}
                url='https://calendly.com/homepensionpilot/homepension-chat'
                rootElement={document.getElementById("root")}
                text="Click here to schedule!"
                textColor="#ffffff"
                color="#00a2ff"
            />
        </div>
    )
}

export default HomePage;