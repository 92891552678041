import React from 'react';

const BMOlogo = ({
    width
}) => {

    const height = 50/144 * width;

    return (
        <svg width={width} height={height} viewBox="0 0 144 50" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.2986 19.4444C15.2986 22.0555 14.1042 23.5 10.9583 23.5H8.27083V15.5208C8.61111 15.4861 9.75 15.4653 10.0903 15.4653H11.0069C14.2153 15.4653 15.2986 16.8264 15.2986 19.4444ZM21.6875 29.8611C21.6875 25.743 18.3264 24.25 14.25 24.1597V24.0069C17.7222 23.7222 20.5625 22.2708 20.5625 18.7708C20.5625 14.875 17.3403 14.1458 13.7361 14.1458C9.40277 14.1458 4.31944 14.2639 0 14.1458V15.4861C0.909722 15.4236 3.13194 15.5486 3.13194 16.6944V33.2778C3.13194 34.3889 0.854166 34.5417 0 34.5417V35.8472C4.16667 35.7361 9.13194 35.8472 13.3333 35.8472C17.75 35.8472 21.6875 34.3542 21.6875 29.8611ZM16.1319 29.4375C16.1319 32.8472 14.9444 34.4653 10.8194 34.4653H8.25V24.9097H10.9861C14.7569 24.9097 16.1528 26.1805 16.1528 29.4097L16.1319 29.4375Z" fill="#0079C1"/>
            <path d="M49.6389 35.8125C47.6944 35.8125 45.7569 35.8125 43.8194 35.8472V34.5139C44.7014 34.5139 47.1458 34.4653 47.1458 33.2778V17.875H47.0764L38.3125 36.1111H37.2569L28.7083 17.8403H28.6458V33.2778C28.6458 34.493 31.0694 34.5139 31.9861 34.5139L32.0278 35.8472C29.6041 35.7708 26.2986 35.7153 23.9167 35.8472V34.5139C24.8403 34.5139 26.9236 34.4375 26.9236 33.2778V16.6667C26.9236 15.5347 24.7708 15.4375 23.9167 15.4375V14.0972C26.6944 14.2153 29.6389 14.2153 32.3958 14.0972L39.7014 29.5069L47.1805 14.1528C49.8333 14.2708 52.8333 14.2361 55.5139 14.1181V15.4583C54.6319 15.4583 52.3194 15.5208 52.3194 16.6875V33.2778C52.3194 34.4653 54.6041 34.5139 55.5139 34.5139V35.8472C53.5486 35.7917 51.5764 35.8125 49.6389 35.8125Z" fill="#0079C1"/>
            <path d="M75.9166 24.9583C75.9166 29.125 75.5069 34.8819 69.3541 34.8819C63.2014 34.8819 62.7569 29.0972 62.7569 24.9583C62.7569 20.8194 63.2014 15.0764 69.3541 15.0764C75.5069 15.0764 75.9166 20.8333 75.9166 24.9583ZM81.6666 24.9583C81.6666 17.7708 77.2778 13.7361 69.3541 13.7361C61.4305 13.7361 57 17.7361 57 24.9583C57 32.1805 61.3958 36.2083 69.3541 36.2083C77.3125 36.2083 81.6666 32.1875 81.6666 24.9583Z" fill="#0079C1"/>
            <path d="M118.465 0C113.521 0 108.687 1.46622 104.576 4.21326C100.465 6.96029 97.2604 10.8648 95.3683 15.4329C93.4761 20.0011 92.981 25.0277 93.9456 29.8773C94.9102 34.7268 97.2913 39.1813 100.788 42.6777C104.284 46.174 108.738 48.555 113.588 49.5196C118.438 50.4842 123.464 49.9892 128.032 48.097C132.6 46.2048 136.505 43.0005 139.252 38.8892C141.999 34.778 143.465 29.9445 143.465 25C143.465 18.3696 140.831 12.0107 136.143 7.32233C131.454 2.63392 125.096 0 118.465 0Z" fill="#ED1C24"/>
            <path d="M117.701 17.3611L113.361 11.7014C113.271 11.5825 113.154 11.4861 113.021 11.4198C112.887 11.3535 112.74 11.3192 112.59 11.3194C112.445 11.3186 112.302 11.3514 112.172 11.4153C112.042 11.4792 111.928 11.5723 111.84 11.6875L106.674 18.1944V28.4028L111.826 21.8125C111.92 21.6937 112.039 21.5976 112.175 21.5314C112.311 21.4652 112.46 21.4308 112.611 21.4306C112.764 21.43 112.915 21.4648 113.052 21.5323C113.19 21.5997 113.31 21.698 113.403 21.8194L117.764 27.5208C117.846 27.6278 117.951 27.7156 118.071 27.7779C118.191 27.8403 118.323 27.8758 118.458 27.8819C118.594 27.8772 118.726 27.8424 118.846 27.7799C118.966 27.7174 119.071 27.6289 119.153 27.5208L123.514 21.8194C123.608 21.6992 123.728 21.6017 123.865 21.5344C124.002 21.4671 124.153 21.4316 124.306 21.4306C124.457 21.4292 124.607 21.463 124.743 21.5293C124.879 21.5955 124.998 21.6925 125.09 21.8125L130.229 28.3958V18.1875L125.062 11.6806C124.973 11.5653 124.858 11.4721 124.727 11.4083C124.596 11.3444 124.451 11.3117 124.306 11.3125C124.157 11.3138 124.01 11.3488 123.876 11.415C123.743 11.4811 123.626 11.5767 123.535 11.6944L119.229 17.3611C119.14 17.4786 119.024 17.5737 118.891 17.6388C118.759 17.7039 118.613 17.7372 118.465 17.7361C118.318 17.7356 118.172 17.7016 118.04 17.6366C117.908 17.5717 117.792 17.4774 117.701 17.3611ZM130.264 35.8819H106.653V28.3958H130.264V35.8819Z" fill="white"/>
        </svg>
    )
}

export default BMOlogo;