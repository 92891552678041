import React from 'react';
import { dollars } from '../../../utils/formatter';
import { Divider } from '@blueprintjs/core';

const PersonalizedSummary = ({
    monthlyIncome,
    homeVal,
    yearsInHome,
    mortgage,
    lifeExpectancy,
    homeGrowthRate,
    mortgageRate,
    mortgageTerm,
    renewalRate
}) => {

    const formattedMonthlyIncome = dollars(monthlyIncome);
    const formattedHomeVal = dollars(homeVal);
    const formattedMortgage = dollars(mortgage);
    const formattedInterestRate = Math.round(((mortgageRate * 100) - 100) * 100) / 100;
    const formattedRenewalRate = Math.round(((renewalRate * 100) - 100) * 100) / 100;
    const formattedGrowthRate = Math.round(((homeGrowthRate * 100) - 100) * 100) / 100;

    return (
        <div className='PrintBoxSection greyBG'>
            <h3 className='margin-top-4 margin-bottom-0 font-800'>
                Personalized Summary
            </h3>
            <div className='Columns margin-top-0'>
                <div className='ColumnA'>
                    <div className='SummaryPoint'>
                        <span className='SummaryLabel font-600'>
                            Lifetime monthly income
                        </span>
                        <span className='SummaryValue'>
                            {formattedMonthlyIncome}/month
                        </span>
                    </div>
                </div>
                <div className='ColumnB'>
                    <div className='SummaryPoint'>
                        <span className='SummaryLabel font-600'>
                            <em>
                                For a single life annuity with a 10-year guarantee
                            </em>
                        </span>
                    </div>
                </div>
            </div>
            <Divider />
            <div className='Columns margin-top-0'>
                <div className='ColumnA'>
                    <div className='SummaryPoint'>
                        <span className='SummaryLabel font-600'>
                            Approximate home value
                        </span>
                        <span className='SummaryValue'>
                            {formattedHomeVal}
                        </span>
                    </div>
                    <div className='SummaryPoint'>
                        <span className='SummaryLabel font-600'>
                            Reverse mortgage amount
                        </span>
                        <span className='SummaryValue'>
                            {formattedMortgage}
                        </span>
                    </div>
                    <div className='SummaryPoint'>
                        <span className='SummaryLabel font-600'>
                            Interest rate
                        </span>
                        <span className='SummaryValue'>
                            {formattedInterestRate}% (5 year term)
                        </span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PersonalizedSummary;