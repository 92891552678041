import { Divider } from '@blueprintjs/core';
import React from 'react';

const QuoteDocFooter = ({
}) => {

    return (
        <>
            <div className='PrintBoxSection greyBG margin-top-16'>
                <h4 className='margin-top-4 margin-bottom-4'>
                    For more information, please contact HomePension
                </h4>
                <p className='margin-top-4 margin-bottom-4'>contact@gethomepension.com</p>
                <p className='margin-bottom-4'>tryhomepension.com</p>
            </div>
        </>
    )
}

export default QuoteDocFooter;