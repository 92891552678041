import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';

const Footer = (props) => {
    return (
        <div className='HomePageFooter Footer'>
            <div className='footer-content'>
                {/* <h2 className='margin-bottom-4'>About us</h2> */}
                <p className='text-large'>
                    HomePension is an initiative of <a href="http://hellokoru.com/" target="_blank">Koru</a>, a venture studio owned by the <a href='https://www.otpp.com/en-ca/' target="_blank">Ontario Teachers Pension Fund</a>, and in collaboration with <a href='https://www.homeequitybank.ca/' target="_blank">HomeEquity Bank</a>.
                </p>
            </div>
            <div className='FooterLinks'>
                <p className='font-600'>HomePension</p>
                <Link to='/faq'>FAQs</Link>
            </div>
        </div>
    )
}

export default Footer;