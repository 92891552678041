import React from 'react';

const EmpireLifeLogo = ({
    width
}) => {

    const height = 33.7/106.7 * width;

    return (
        <svg 
            version="1.1" 
            id="Layer_1" 
            x="0px" y="0px"
	        viewBox="0 0 106.7 33.7"
            width={width}
            height={height}
        >
            <g>
                <g>
                    <g>
                        <polygon 
                            fill='#006595' 
                            points="41.3,0 41.3,15.4 50.9,15.4 50.9,13 44.1,13 44.1,8.9 49.3,8.9 49.3,6.5 44.1,6.5 44.1,2.4 
                            50.5,2.4 50.5,0"
                        />
                        <g>
                            <path fill='#006595' d="M52.5,4.4h2.6v1.3c0,0.4,0,0.8,0,0.8h0c0.5-1.1,1.9-2.3,3.6-2.3c1.7,0,2.8,0.8,3.2,2.3h0
                                c0.6-1.2,2-2.3,3.8-2.3c2.3,0,3.6,1.3,3.6,4.2v7.1h-2.7V8.8c0-1.3-0.2-2.2-1.5-2.2c-1.4,0-2.3,1.1-2.7,2.5
                                c-0.1,0.4-0.1,0.9-0.1,1.4v4.8h-2.7V8.8c0-1.2-0.2-2.2-1.5-2.2c-1.4,0-2.4,1.1-2.7,2.5c-0.1,0.4-0.1,0.9-0.1,1.4v4.8h-2.7V4.4z"
                                />
                            <path fill='#006595' d="M71.4,4.4h2.5v0.8c0,0.4,0,0.8,0,0.8h0c0,0,1-1.8,3.5-1.8c3,0,4.9,2.4,4.9,5.8c0,3.5-2.1,5.8-5,5.8
                                c-2.2,0-3.2-1.5-3.2-1.5h0c0,0,0,0.4,0,0.9v4.6h-2.7V4.4z M76.8,13.3c1.5,0,2.8-1.2,2.8-3.4c0-2.1-1.1-3.5-2.7-3.5
                                c-1.4,0-2.8,1-2.8,3.5C74.1,11.6,75,13.3,76.8,13.3"/>
                            <path fill='#006595' d="M89,4.4h2.6v1.9c0,0.4,0,0.8,0,0.8h0c0.5-1.6,1.8-2.8,3.5-2.8c0.3,0,0.5,0,0.5,0V7c0,0-0.3,0-0.6,0
                                c-1.2,0-2.5,0.7-3,2.3c-0.2,0.5-0.2,1.1-0.2,1.8v4.4H89V4.4z"/>
                            <path fill='#006595' d="M101.8,4.1c3.2,0,4.9,2.3,4.9,5.2c0,0.3-0.1,1.1-0.1,1.1h-7.6c0.2,1.9,1.7,2.9,3.3,2.9
                                c1.8,0,3.2-1.2,3.2-1.2l1.1,2c0,0-1.7,1.6-4.4,1.6c-3.7,0-5.9-2.6-5.9-5.8C96.2,6.5,98.6,4.1,101.8,4.1 M104,8.5
                                c0-1.5-1-2.3-2.2-2.3c-1.4,0-2.4,0.9-2.6,2.3H104z"/>
                            <rect x="84" y="4.4" fill='#006595' width="2.7" height="11"/>
                            <path fill='#006595' d="M86.9,1.5c0,0.8-0.7,1.5-1.5,1.5c-0.8,0-1.5-0.7-1.5-1.5c0-0.8,0.7-1.5,1.5-1.5C86.2,0,86.9,0.7,86.9,1.5"
                                />
                            <polygon fill='#006595' points="41.3,18.1 44.1,18.1 44.1,31.1 50.9,31.1 50.9,33.5 41.3,33.5 				"/>
                            <rect x="52.7" y="22.5" fill='#006595' width="2.7" height="11"/>
                            <path fill='#006595' d="M58.2,24.6h-1.4v-2.2h1.4v-0.3c0-3.6,3-4.1,4.5-4.1c0.6,0,0.9,0.1,0.9,0.1v2.3c0,0-0.2,0-0.6,0
                                c-0.8,0-2.1,0.2-2.1,1.8v0.3h2.4v2.2H61v8.8h-2.7V24.6z"/>
                            <path fill='#006595' d="M69.3,22.2c3.2,0,4.9,2.3,4.9,5.2c0,0.3-0.1,1.1-0.1,1.1h-7.6c0.2,1.9,1.7,2.9,3.3,2.9
                                c1.8,0,3.2-1.2,3.2-1.2l1.1,2c0,0-1.7,1.6-4.4,1.6c-3.7,0-5.9-2.6-5.9-5.8C63.7,24.6,66,22.2,69.3,22.2 M71.5,26.6
                                c0-1.5-1-2.3-2.2-2.3c-1.4,0-2.4,0.9-2.6,2.3H71.5z"/>
                            <path fill='#006595' d="M55.6,19.6c0,0.8-0.7,1.5-1.5,1.5c-0.8,0-1.5-0.7-1.5-1.5c0-0.8,0.7-1.5,1.5-1.5
                                C54.9,18.1,55.6,18.8,55.6,19.6"/>
                            <path fill='#006595' d="M22.2,14.1c1.6,0,3.1,0.4,4.4,1.2c0.7,0.4,1.3,0.9,1.8,1.4c1.2,1.2,2,2.7,2.4,4.4c0-0.1,0.1-0.2,0.1-0.4
                                c1.8-6.8-1.2-14.1-7.3-17.6c-1.2-0.7-2.5-1.2-3.8-1.6c-0.6-0.2-1.2-0.2-1.7-0.2c-3,0-5.7,2-6.5,5c-0.8,2.9,0.5,6,3.1,7.5
                                c0.5,0.3,1.1,0.5,1.6,0.7c0.6,0.2,1.2,0.2,1.7,0.2c0.6,0,1.3-0.1,1.9-0.3C20.7,14.2,21.4,14.1,22.2,14.1"/>
                            <path fill='#B0BC22' d="M10.4,12.4C9.6,11,9.2,9.5,9.2,8c0-0.8,0.1-1.5,0.3-2.3c0.4-1.6,1.4-3.1,2.6-4.3c-0.1,0-0.2,0.1-0.4,0.1
                                C4.8,3.4,0,9.6,0,16.6c0,1.4,0.2,2.7,0.5,4.1c0.2,0.6,0.4,1.1,0.7,1.6c1.5,2.6,4.6,3.9,7.6,3.1c2.9-0.8,5-3.4,5-6.5
                                c0-0.6-0.1-1.2-0.2-1.7c-0.2-0.6-0.4-1.1-0.7-1.6c-0.3-0.5-0.7-1-1.2-1.5C11.2,13.6,10.7,13,10.4,12.4"/>
                            <path fill='#B0BC22' d="M14.7,23.4c-0.8,1.3-1.9,2.5-3.3,3.2c-0.7,0.4-1.4,0.7-2.1,0.9c-1.6,0.4-3.4,0.4-5-0.1
                                c0.1,0.1,0.2,0.2,0.3,0.3c5,5,12.9,6,19,2.5c1.2-0.7,2.3-1.5,3.3-2.5c0.4-0.4,0.8-0.9,1.1-1.4c1.5-2.6,1.1-5.9-1.1-8.1
                                c-2.2-2.1-5.5-2.6-8.1-1.1c-0.5,0.3-1,0.6-1.4,1.1c-0.4,0.4-0.8,0.9-1.1,1.4c-0.3,0.5-0.5,1.1-0.7,1.7
                                C15.4,22.1,15.1,22.8,14.7,23.4"/>
                            <path fill='#006595' d="M75.4,22.2c0.6,0,1.2,0.5,1.2,1.2c0,0.7-0.5,1.2-1.2,1.2c-0.6,0-1.1-0.5-1.1-1.2
                                C74.3,22.7,74.8,22.2,75.4,22.2 M75.4,24.4c0.5,0,1-0.4,1-1c0-0.6-0.4-1-1-1c-0.5,0-0.9,0.4-0.9,1C74.4,24,74.9,24.4,75.4,24.4
                                M75,22.8h0.4c0.2,0,0.4,0.1,0.4,0.4c0,0.2-0.1,0.3-0.2,0.3v0c0,0,0,0,0,0.1l0.2,0.5h-0.2l-0.3-0.5h-0.2V24H75V22.8z M75.4,23.4
                                c0.1,0,0.2-0.1,0.2-0.2c0-0.1-0.1-0.2-0.2-0.2h-0.2v0.5H75.4z"/>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )
}

export default EmpireLifeLogo;