import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import InputForm from './InputForm/InputForm';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import QUOTE from './queries/quote.js'
import GET_TOKEN from './queries/getToken';
import { setLocalToken } from '../../utils/authUtils';
import QuoteInfo from './QuoteInfo/QuoteInfo';
import { Button, Divider } from '@blueprintjs/core';
import ChooseAmount from './ChooseAmount/ChooseAmount';
import VisualizePlan from './VisualizePlan/VisualizePlan';
import SolutionComparison from './SolutionComparison/SolutionComparison';
import PrintModal from './PrintModal/PrintModal';
import QuoteDocument from '../QuoteDocument/QuoteDocument';
import accounting from 'accounting-js';
import FriendlyError from './FriendlyError/FriendlyError';
import RECORD_PRINTOUT from './queries/recordPrintout';

const mockData = {
    quote: {
        chipQuoteMax: 342500,
        annuityOffers: [
            {
                insurer: 'RBC Life Insurance Company',
                monthlyIncome: 2013
            },
            {
                insurer: 'Desjardins Financial Security',
                monthlyIncome: 1999
            },
            {
                insurer: 'Equitable Life',
                monthlyIncome: 1990
            },
            {
                insurer: 'BMO Insurance',
                monthlyIncome: 1963
            }
        ]
    }
}

function SmoothVerticalScrolling(e, time, where) {
    var eTop = e.getBoundingClientRect().top;
    var eAmt = eTop / 100;
    var curTime = 0;
    while (curTime <= time) {
        window.setTimeout(SVS_B, curTime, eAmt, where);
        curTime += time / 100;
    }
}

function SVS_B(eAmt, where) {
    if(where == "center" || where == "")
        window.scrollBy(0, eAmt / 2);
    if (where == "top")
        window.scrollBy(0, eAmt);
}

const Calculator = ({

}) => {

    const [getQuote, { loading, error, data: quoteData }] = useLazyQuery(QUOTE)
    const { loading: loadingToken, error: errorToken, data: tokenData } = useQuery(GET_TOKEN, {
        onCompleted: (response) => {
            setLocalToken(response.getToken)
        }
    });

    // mutation for logging print out requests
    const [recordPrintout, { 
        loading: savingPrintout, 
        error: printoutError, 
        data: printoutData 
    }] = useMutation(RECORD_PRINTOUT);

    useEffect(() => {
        if (tokenData && tokenData.data) {
            setLocalToken(tokenData.data.getToken)
        }
    },[tokenData])

    const data = quoteData;

    const [homeVal, setHomeVal] = useState(null);
    const [homeValString, setHomeValString] = useState('');
    const onSetHomeVal = (e) => {

        const int = accounting.unformat(e.target.value);
        const newString = accounting.formatMoney(int, { precision: 0 });

        if (int === 0) {
            setHomeValString('');
            setHomeVal(int);
        }

        else {
            setHomeVal(int);
            setHomeValString(newString);
        }

    }

    const [existingMortgage, setExistingMortgage] = useState(0);
    const [existingMortgageString, setExistingMortgageString] = useState('');
    const onSetExistingMortgage = (e) => {

        const int = accounting.unformat(e.target.value);
        const newString = accounting.formatMoney(int, { precision: 0 });

        if (int === 0) {
            setExistingMortgageString('');
            setExistingMortgage(int);
        }

        else {
            setExistingMortgage(int);
            setExistingMortgageString(newString);
        }

    }

    const [desiredRate, setDesiredRate] = useState(null);
    const [desiredIncome, setDesiredIncome] = useState(null);

    const [desiredMortgage, setDesiredMortgage] = useState(null);
    const onSetDesiredMortgage = (val) => setDesiredMortgage(val);
    
    const onSetDesiredRate = (rate) => {
        setDesiredRate(rate);
        setDesiredIncome(rate.monthlyIncome);
        setDesiredMortgage(data.quote.chipQuoteMax);
    }

    const [yearsInHome, setYearsInHome] = useState(25);
    const onSetYearsInHome = (e) => {
        setYearsInHome(e.target.value);
        if (Number(yearsToLive) < Number(e.currentTarget.value)) {
            setYearsToLive(e.currentTarget.value);
        }
    }

    const [showingPrintModal, togglePrintModal] = useState(false);
    const openPrintModal = () => togglePrintModal(true);
    const closePrintModal = () => togglePrintModal(false);

    const [age1, setAge1] = useState('');
    const onSetAge1 = (e) => {
        let val = e.target.value
        if (isNaN(val)) {
            return;
        }
        if (val.toString().includes('e')) {
            val = val.replace('e', '');
        }
        return e.target.value === '' ? setAge1('') : setAge1(parseInt(e.target.value));
    }

    const [gender1, setGender1] = useState(null);
    const [gender2, setGender2] = useState(null);
    const [postCode, setPostCode] = useState('');
    const [city, setCity] = useState('');
    const [homeType, setHomeType] = useState('A1 - Single Family Dwelling');

    const [age2, setAge2] = useState('');
    const onSetAge2 = (e) => {
        let val = e.target.value;
        if (isNaN(e.target.value)) {
            return;
        }
        if (val.toString().includes('e')) {
            val = val.replace('e', '');
        }
        return e.target.value === '' ? setAge2('') : setAge2(parseInt(e.target.value));
    }

    const [clientName, setClientName] = useState('');
    const onSetClientName = (e) => setClientName(e.target.value);

    const [advisorName, setAdvisorName] = useState('');
    const onSetAdvisorName = (e) => setAdvisorName(e.target.value);

    const [advisorPhone, setAdvisorPhone] = useState('');
    const onSetAdvisorPhone = (e) => setAdvisorPhone(e.target.value);

    const [advisorEmail, setAdvisorEmail] = useState('');
    const onSetAdvisorEmail = (e) => setAdvisorEmail(e.target.value);

    const [advisorNotes, setAdvisorNotes] = useState('');
    const onSetAdvisorNotes = (e) => setAdvisorNotes(e.target.value);

    const [selectedOffer, setSelectedOffer] = useState(mockData.quote.annuityOffers[0]);
    const onSetSelectedOffer = (offer) => setSelectedOffer(offer);

    const [yearsToLive, setYearsToLive] = useState(25);
    const onSetYearsToLive = (e) => {
        if (Number(yearsToLive) > Number(e.currentTarget.value) && Number(yearsInHome) > Number(e.currentTarget.value)) {
            setYearsInHome(e.currentTarget.value);
        }
        setYearsToLive(e.currentTarget.value);
    }

    const [homeGrowthRate, setHomeGrowthRate] = useState(1.03);
    const onSetHomeGrowthRate = (e) => setHomeGrowthRate(e.currentTarget.value);

    const [mortgageRate, setMortgageRate] = useState(1.0911);
    const [mortgageTerm, setMortgageTerm] = useState(5);
    const [mortgageRateTerm, setMortgageRateTerm] = useState('1.0911/5');
    const [renewalRate, setRenewalRate] = useState(1.0911);
    const onSetMortgageRate = (e, updateRenewalRate) => {
        console.log('val', e.currentTarget.value);
        const val = e.currentTarget.value;
        const rate = parseFloat(val.substring(0, val.indexOf('/')));
        const term = parseInt(val.substring(val.indexOf('/')+1));
        console.log('mortgage rate', rate);
        setMortgageRateTerm(e.currentTarget.value);
        setMortgageRate(rate);
        setMortgageTerm(term);
        if (updateRenewalRate) {
            setRenewalRate(rate);
        }
    };
    const onSetRenewalRate = (e) => {
        console.log('renewal rate', e.currentTarget.value)
        const rate = Number(e.currentTarget.value);
        console.log('rate', rate);
        setRenewalRate(rate);
    }

    useEffect(() => {
        if (data && data.quote && data.quote.annuityOffers && data.quote.annuityOffers.length > 0) {
            setDesiredRate(data.quote.annuityOffers[0]);
            setDesiredIncome(data.quote.annuityOffers[0].monthlyIncome);
            setDesiredMortgage(data.quote.chipQuoteMax);
            setYearsToLive(95 - Number(data.quote.age1));
            setYearsInHome(95 - Number(data.quote.age1));

            setTimeout(() => {
                const d = document.getElementById("quote");
                const topPos = d ? d.offsetTop : null;
                window.scrollTo({ top: topPos - 100, behavior: 'smooth' })
            }, 200);

        }

    }, [data])

    const onSetDesiredIncome = (val) => {
        const mortgageToIncomeRatio = data.quote.chipQuoteMax/desiredRate.monthlyIncome;
        const desiredMortgage = mortgageToIncomeRatio * val;
        if (val < 250) return val; 
        setDesiredIncome(val);
        setDesiredMortgage(desiredMortgage);
    }

    const handlePrint = () => {
        recordPrintout({
            variables: {
                input: {
                    age1: age1,
                    gender1: gender1,
                    age2: age2 !== "" ? age2 : null,
                    gender2: gender2,
                    postalCode: postCode, 
                    homeVal: homeVal,
                    existingMortgage: existingMortgage,
                    user: window.localStorage.getItem('homepension-user'),
                    city: city,
                    homeType: homeType,
                    selectedRM: desiredMortgage,
                    selectedInsurer: desiredRate.insurer,
                    selectedMonthlyIncome: desiredIncome
                }
            }
        })
        setTimeout(() => window.print(), 300);
    }

    const olderAge = age1 > age2 ? age1 : age2;
    const guaranteePeriod = 90 - olderAge < 10 ? 90 - olderAge : 10;

    return (
        <>
            <Helmet>
                <title>HomePension Quote {clientName ? `for ${clientName}` : ''}</title>
            </Helmet>
            <div className='page'>
                <h1 className='page-narrow-section'>Learn how much you can get</h1>
                <InputForm 
                    age1={age1}
                    onSetAge1={onSetAge1}
                    gender1={gender1}
                    setGender1={setGender1}
                    age2={age2}
                    onSetAge2={onSetAge2}
                    gender2={gender2}
                    setGender2={setGender2}
                    postCode={postCode}
                    setPostCode={setPostCode}
                    city={city}
                    setCity={setCity}
                    homeType={homeType}
                    setHomeType={setHomeType}
                    homeVal={homeVal}
                    homeValString={homeValString}
                    onSetHomeVal={onSetHomeVal}
                    existingMortgage={existingMortgage}
                    existingMortgageString={existingMortgageString}
                    onSetExistingMortgage={onSetExistingMortgage}
                    getQuote={getQuote}
                    loadingQuote={loading}
                />
                <br/>
                <br/>
                {data && (data.quote === null || data.quote.error || (data.quote.annuityOffers && data.quote.annuityOffers.length === 0)) && (
                    <FriendlyError error={data.quote && data.quote.error ? data.quote.error : null} />
                )}
                {data && data.quote && data.quote.annuityOffers && data.quote.annuityOffers.length > 0 && desiredRate && (
                    <>
                        <div className='page-narrow-section'>
                            <Divider />
                            <QuoteInfo
                                openPrintModal={openPrintModal}
                                desiredRate={desiredRate}
                                onSetDesiredRate={onSetDesiredRate}
                                guaranteePeriod={guaranteePeriod}
                                {...data.quote}
                            />
                            <Divider />
                            <ChooseAmount
                                desiredIncome={desiredIncome}
                                onSetDesiredIncome={onSetDesiredIncome}
                                desiredMortgage={desiredMortgage}
                                onSetDesiredMortgage={onSetDesiredMortgage}
                                maxIncome={desiredRate.monthlyIncome}
                                maxMortgage={data.quote.chipQuoteMax}
                            />
                        </div>
                        <VisualizePlan 
                            age={data.quote.age1}
                            homeVal={data.quote.homeVal}
                            monthlyIncome={desiredIncome}
                            yearsInHome={yearsInHome}
                            onSetYearsInHome={onSetYearsInHome}
                            yearsToLive={yearsToLive}
                            onSetYearsToLive={onSetYearsToLive}
                            homeGrowthRate={homeGrowthRate}
                            onSetHomeGrowthRate={onSetHomeGrowthRate}
                            mortgage={desiredMortgage}
                            mortgageRate={mortgageRate}
                            mortgageRateTerm={mortgageRateTerm}
                            mortgageTerm={mortgageTerm}
                            onSetMortgageRate={onSetMortgageRate}
                            renewalRate={renewalRate}
                            onSetRenewalRate={onSetRenewalRate}
                            desiredIncome={desiredIncome}
                            onSetDesiredIncome={onSetDesiredIncome}
                            maxIncome={desiredRate.monthlyIncome}
                            openPrintModal={openPrintModal}
                        />
                        <div className='page-narrow-section'>
                            <Divider />
                            <SolutionComparison 
                                monthlyIncome={desiredIncome}
                                yearsInHome={yearsInHome}
                                yearsToLive={yearsToLive}
                                homeVal={data.quote.homeVal}
                                homeGrowthRate={homeGrowthRate}
                                mortgageVal={desiredMortgage}
                                mortgageRate={mortgageRate}
                                mortgageTerm={mortgageTerm}
                                renewalRate={renewalRate}
                            />
                            <Divider />
                            <div className='FinalCTA margin-top-48 margin-bottom-72'>
                                <h2 className='margin-bottom-4'>
                                    Get started today
                                </h2>
                                <p className='text-large margin-bottom-32'>
                                    Share this quote with your client
                                </p>
                                <Button
                                    intent='primary'
                                    icon='print'
                                    text='Save this quote'
                                    onClick={openPrintModal}
                                />
                            </div>
                            <Divider />
                            <div className='margin-top-24 three-quarter-opacity'>
                                <p className='text-xs'>
                                    1. Calculation results and chart are approximations based on the data you have entered and are for illustration purposes. HomePension does not make any representations or warranties with respect to the calculation results. External factors are not accounted for in the calculations and may affect future projections and are based on certain assumptions. The rate entered is a sample rate and is not considered a rate guarantee. HomePension may change or update calculations without notification. This information is not intended as specific financial, legal, or tax advice for any individual and should not be relied on as such. Should the home owner decide to proceed, an independent appraisal of the home, among other things, will be required in order to establish a precise evaluation.
                                </p>
                            </div>
                        </div>
                    </>
                )} 
                <PrintModal 
                    isOpen={showingPrintModal}
                    onClose={closePrintModal}
                    onPrint={handlePrint}
                    clientName={clientName}
                    advisorName={advisorName}
                    advisorPhone={advisorPhone}
                    advisorEmail={advisorEmail}
                    onSetClientName={onSetClientName}
                    onSetAdvisorName={onSetAdvisorName}
                    onSetAdvisorPhone={onSetAdvisorPhone}
                    onSetAdvisorEmail={onSetAdvisorEmail}
                    advisorNotes={advisorNotes}
                    onSetAdvisorNotes={onSetAdvisorNotes}
                    selectedOffer={selectedOffer}
                    onSetSelectedOffer={onSetSelectedOffer}
                />
            </div>
            {data && desiredRate && data.quote && data.quote.annuityOffers && data.quote.annuityOffers.length && (
                <QuoteDocument 
                    monthlyIncome={desiredIncome}
                    homeVal={data.quote.homeVal}
                    quoteType={data.quote.quoteType}
                    guaranteePeriod={guaranteePeriod}
                    yearsInHome={yearsInHome}
                    mortgage={desiredMortgage}
                    lifeExpectancy={Number(data.quote.age1)+Number(yearsToLive)}
                    homeGrowthRate={homeGrowthRate}
                    yearsToLive={yearsToLive}
                    mortgageRate={mortgageRate}
                    carrier={desiredRate.insurer}
                    existingMortgage={data.quote.existingMortgage}
                    clientName={clientName}
                    advisorName={advisorName}
                    advisorPhone={advisorPhone}
                    advisorEmail={advisorEmail}
                    advisorNotes={advisorNotes}
                    mortgageTerm={mortgageTerm}
                    renewalRate={renewalRate}
                />
            )}
        </>
    )
}

export default Calculator;