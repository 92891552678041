

export const localStorageKey = `homepension-token`;

export const setLocalToken = (token) => {
    window.localStorage.setItem(localStorageKey, token);
}

export const getToken = () => {
    if (window.localStorage.length === 0) {
        return null;
    } else {
        return window.localStorage.getItem(localStorageKey);
    }
}