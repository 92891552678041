import React from 'react';
import { dollars } from '../../../../utils/formatter';
import RBCLogo from '../../../ui/logos/rbcLogo/rbcLogo';
import { Divider } from '@blueprintjs/core';
import DesjardinsLogo from '../../../ui/logos/DesjardinsLogo/DesjardinsLogo';
import RBClogo from '../../../ui/logos/PNGs/rbc_insurance.png'
import EquitableLifelogo from '../../../ui/logos/PNGs/equitable_life.png'
import './InsurerCard.css';
import BMOlogo from '../../../ui/logos/BMOlogo/BMOlogo';
import SunLifeLogo from '../../../ui/logos/SunLifeLogo/SunLifeLogo';
import EmpireLifeLogo from '../../../ui/logos/EmpireLifeLogo/EmpireLifeLogo';
import CanadaLifeLogo from '../../../ui/logos/CanadaLifeLogo/CanadaLifeLogo';
import ManulifeLogo from '../../../ui/logos/ManulifeLogo/ManulifeLogo';

export const getInsurerLogo = (insurer, width) => {
    let insurerLogo;
    switch (insurer) {
        case 'RBC Life Insurance Company': 
            insurerLogo = (
                <div className='RBC'>
                    <img src={RBClogo} />
                </div>
            ); 
            break;
        case 'Desjardins Financial Security': 
            insurerLogo = (
                <div className='Desjardins'>
                    <DesjardinsLogo width={width ? width: 100} />
                </div>
            ); 
            break;
        case 'Equitable Life':
            insurerLogo = (
                <div className='EquitableLife'>
                    <img src={EquitableLifelogo} />
                </div>
            ); 
            break;
        case 'BMO Insurance':
            insurerLogo = (
                <div className='BMO'>
                    <BMOlogo width={width ? width : 88} />
                </div>
            ); 
            break;
        case 'Sun Life Assurance Company of Canada': 
            insurerLogo = (
                <div className='SunLife'>
                    <SunLifeLogo width={width ? width : 104} />
                </div>
            ); 
            break;
        case 'Empire Life':
            insurerLogo = (
                <div className='SunLife'>
                    <EmpireLifeLogo width={width ? width : 96} />
                </div>
            ); 
            break;
        case 'Manulife Investments':
            insurerLogo = (
                <div className='Manulife'>
                    <ManulifeLogo width={width ? width : 104} />
                </div>
            ); 
            break;
        case 'Canada Life':
        default:  
            insurerLogo = (
                <div className='InsuranceLogo CanadaLife'>
                    <CanadaLifeLogo width={width ? width : 104} />
                </div>
            ); 
            break;
    }
    return insurerLogo;
}

const InsurerCard = ({
    monthlyIncome,
    insurer
}) => {

    let insurerLogo = getInsurerLogo(insurer);
    
    return (
        <div className='InsurerCard'>
            <div className='Amount'>
                {dollars(monthlyIncome)}/mo
            </div>
            <Divider />
            <div className='InsurerLogo'>
                {insurerLogo}
            </div>
        </div>
    )
}

export default InsurerCard;