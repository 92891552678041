import React from 'react';

const BenefitSummary = ({
    advisorNotes
}) => {

    const defaultNotes = `HomePension provides guaranteed income, every month, for the rest of your life, even after you sell your home.`

    return (
        <div className='Columns'>
            <div className='ColumnA'>
                <p className='font-800 margin-bottom-0'>Benefits of HomePension</p>
                <p>{advisorNotes ? advisorNotes : defaultNotes}</p>
            </div>
            <div className='ColumnB'>
                <ul>
                    <li>Guaranteed monthly income for life</li>
                    <li>No monthly payments</li>
                    <li>Never have to sell your home</li>
                </ul>
            </div>
        </div>
    )
}

export default BenefitSummary;