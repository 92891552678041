import React from 'react';

const ManulifeLogo = ({
    width
}) => {

    const height = 151/695 * width;

    return (
        <svg width={width} height={height} viewBox="0 0 695 151" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M345.461 89.3517L334.744 90.6286C325.424 91.7842 317.258 95.0993 317.258 103.013C317.258 108.631 322.103 112.469 328.359 112.469C335.763 112.469 341.883 107.612 345.717 102.756V89.3528L345.461 89.3517ZM345.589 123.447V115.405C341.763 119.876 335.635 125.109 324.782 125.109C312.277 125.109 301.938 117.187 301.938 103.655C301.938 87.1762 317.378 81.1812 332.442 79.5196L338.314 78.8762C344.057 78.3615 346.094 75.1763 346.094 71.348C346.094 66.6204 341.635 63.1774 334.873 63.1774C327.084 63.1774 319.552 68.7869 316.745 73.2576L307.809 62.6632C313.296 55.5032 323.002 50.5273 335.635 50.5273C351.966 50.5273 361.415 58.9548 361.415 73.0009V123.447L345.589 123.447Z" fill="black"/>
            <path d="M391.143 123.453H375.445V52.1954H391.143V60.2374C396.758 55.1243 405.18 50.5254 414.372 50.5254C422.794 50.5254 434.272 55.3899 434.272 72.7504V123.453H418.583V76.8357C418.583 69.8126 415.648 63.8089 408.372 63.8089C402.372 63.8089 394.72 68.9135 391.143 72.2451V123.453Z" fill="black"/>
            <path d="M489.51 52.1914H505.207V123.449H488.996V115.792C484.657 119.878 477.638 125.111 468.446 125.111C459.262 125.111 446.5 120.254 446.5 102.38V52.1914H462.197V97.267C462.197 105.575 465.775 111.321 473.17 111.321C479.299 111.321 485.042 107.356 489.51 102.38V52.1914Z" fill="black"/>
            <path d="M538.244 123.453H522.547V29.7139L538.244 14.1348V123.453Z" fill="black"/>
            <path d="M571.928 123.454H555.589V52.1961H571.928V123.454ZM563.763 20.1387C569.121 20.1387 573.709 24.6094 573.709 30.0993C573.709 35.5892 569.121 40.1884 563.763 40.1884C558.268 40.1884 553.672 35.5892 553.672 30.0993C553.672 24.6094 558.268 20.1387 563.763 20.1387Z" fill="black"/>
            <path d="M621.687 65.2166H606.751V123.456H591.062V65.2166H581.484V52.1899H591.062V37.5016C591.062 25.751 598.586 15.1562 613.393 15.1562C620.283 15.1562 626.026 17.067 629.467 18.8563L624.494 31.121C622.32 29.844 619.007 28.5687 615.815 28.5687C610.834 28.5687 606.751 32.2684 606.751 38.4007V52.1899H625.384L621.687 65.2166Z" fill="black"/>
            <path d="M672.477 80.0297C672.477 71.3538 666.991 63.1743 657.421 63.1743C648.237 63.1743 641.082 71.8591 640.825 80.0297H672.477ZM640.32 92.0374C640.449 103.531 648.742 111.701 658.568 111.701C665.972 111.701 673.624 106.726 676.945 100.722L686.771 109.911C681.027 118.339 669.926 125.105 657.036 125.105C636.743 125.105 624.109 109.783 624.109 87.815C624.109 66.6262 637.761 50.5332 657.541 50.5332C676.431 50.5332 689.706 62.9178 688.936 92.0374H640.32Z" fill="black"/>
            <path d="M211.867 123.452H194.766V24.5391H213.27L242.372 73.2032H242.628L271.592 24.5391H289.72V123.452H272.482V53.4021H272.234L242.235 103.214L212.123 53.4021H211.867V123.452Z" fill="black"/>
            <path d="M105.844 30.7278V141.999L130.554 117.265V6.00195L105.844 30.7278Z" fill="#00AC5B"/>
            <path d="M56.4219 141.997L81.1319 117.271V6L56.4219 30.7259V141.997Z" fill="#00AC5B"/>
            <path d="M7 141.997L31.7102 117.271V6L7 30.7259V141.997Z" fill="#00AC5B"/>
        </svg>
    )

}

export default ManulifeLogo;