import React from 'react';
import HomePensionLogo from '../../ui/logos/HomePensionLogo/HomePensionLogo';

const QuoteDocHeader = ({
    clientName,
    advisorName,
    advisorPhone,
    advisorEmail
}) => {

    const date = new Date().toLocaleDateString('en-us', { year:"numeric", month:"long", day:"numeric"}) 

    return (
        <>
            <div className='Header'>
                <HomePensionLogo 
                    width={180}
                    fill='#0047FF'
                />
                <p>{date}</p>
            </div>
            <div className='PreparedBy'>
                <span className='AdvisorContactDetail'>
                    Prepared by HomePension
                </span>
                <span className='AdvisorContactDetail'>contact@gethomepension.com</span>
            </div>
        </>
    )
    
}

export default QuoteDocHeader;