import React from 'react';
import './YoutubeEmbed.css'

const YoutubeEmbed = ({

}) => {

    return (
        <div class='embed-container'>
            <iframe 
                src='https://www.youtube.com/embed/V4vNrAa94Rs?controls=0' 
                frameborder='0' allowfullscreen>        
            </iframe>
        </div>    
    )
}

export default YoutubeEmbed;