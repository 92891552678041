import React from 'react';
import './FAQ.css';

const FAQ = ({

}) => {

    return (
        <div className='FAQ page'>
            <h1 className='margin-bottom-32 font-900 hero-text'>Frequently Asked Questions</h1>
            
            <h2>Who is eligible for HomePension?</h2>
            <p>There are two eligibility criteria to enroll in HomePension:</p> 
            <ul>
                <li>The homeowner(s) must be at least 55 years old</li>
                <li>The home that you are borrowing on must be your primary residence (i.e. the homeowner must live in the home for at least 6 months of the year)</li>
            </ul>

            <h2>Will the bank own my home?</h2>
            <p><b>No. </b>
                The homeowner retains title and maintains ownership of the home. It's required for the homeowner to live in the home, pay taxes on time, have home insurance, and maintain the property in good condition. 
            </p>

            <h2>Could I be forced to leave my home?</h2>
            <p><b>No. </b>
                As long as the homeowner lives in the home, pays taxes on time, has home insurance, and maintains the property in good condition. HomePension will never force you to leave your home, even if your loan exceeds your home equity value.
            </p>

            <h2>Will I still have equity in my home once I sell?</h2>
            <p>When you are ready to sell your home, HomePension will receive the initial loan amount plus any interest accrued on that initial loan. The proceeds from the remaining equity is yours to keep, including any appreciation that grows on the house from the time you enrolled in HomePension.</p>
            
            <h2>Can I owe more than my house is worth?</h2>
            <p>HomePension comes with a No Negative Equity Guarantee*, which means the loan will never exceed the fair market value of the home, as long as the conditions of the loan are met.</p>
            <p className='caveat'>*Must maintain property, pay property taxes and homeowners' insurance, and abide by your mortgage obligations. The guarantee excludes administrative expenses and interest that has accumulated after the due date.</p>

            <h2>What if I can't afford payments?</h2>
            <p>There are no monthly payments required as long as the homeowner is living in the home. The loan will only be paid back once the home is sold using the sale proceeds.</p>

            <h2>What are the fees associated with HomePension?</h2>
            <p>In addition to the loan interest, enrolling in HomePension involves the following fees:</p>
            <ul>
                <li>Appraisal fee to determine the value of your home, paid to an external property appraiser</li>
                <li>Independent legal advice fees paid to a lawyer of your choice</li>
                <li>Closing fees collected by HomePension to cover costs including administrations and registration fees. This amount is deducted from proceeds of the loan.</li>
            </ul>

            <h2>How is the interest rate calculated?</h2>
            <p>The interest rate associated with the HomePension loan is based on the Bank of Canada’s prime rates. Clients may select a variable rate or 1, 3, or 5 year term rate.</p>

            <h2>Will my government benefits be affected?</h2>
            <p>Depending on your selected annuity - tax treatment and what is deemed pension income may impact the amount of government benefits you receive. Please talk with a financial advisor to fully understand implications of HomePension on government benefits.</p>

            <h2>What are the tax implications of HomePension?</h2>
            <p>If you use a reverse mortgage to purchase a non-registered life annuity that is non-prescribed (accrual), it is interest tax deductible. In other words, there may be no increase in taxable income that you receive from your annuity as it would be offset by reverse mortgage interest tax deductibility. Please talk with a financial advisor to fully understand specific tax implications of HomePension.</p>

            <h2>What is a life annuity?</h2>
            <p>A life annuity provides guaranteed income payments for as long as you live. A joint life annuity provides payments as long as you or your spouse/partner lives. You have the option to choose a guaranteed period. If you die before the end of that period, your beneficiary will receive the balance of the guaranteed income payments.</p>

        </div>
    )
}

export default FAQ;