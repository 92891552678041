import React from 'react';
import HEBlogo from '../../ui/logos/PNGs/HomeEquity_Bank_HomeEquity_Bank_Launches_Campaign_Highlighting_H.jpg'
import { getInsurerLogo } from '../../Calculator/QuoteInfo/InsurerCard/InsurerCard';

const ProductDescription = ({
    insurer
}) => {

    const insurerLogo = getInsurerLogo(insurer, 'auto');

    return (
        <div className='PrintBoxSection'>
            <div className='ProductDescription'>
                <p>
                    A HomePension is a reverse mortgage that gives you a lifetime income by purchasing an annuity with a portion of the equity in your home. 
                </p> 
                <p>
                    As with a reverse mortgage, a HomePension never requires you to sell or make any regular mortgage payments. 
                </p>
                <div className='Columns'
                    style={{ height: 0, paddingBottom: 0 }}
                >
                    <div className='ColumnA'>
                        <div className='SummaryPoint'>
                            <span className='SummaryLabel font-600'>
                                Reverse Mortgage Provider
                            </span>
                            <span className='SummaryValue'>
                                <img src={HEBlogo} alt='HomeEquity Bank' />
                            </span>
                        </div>
                    </div>
                    <div className='ColumnB'>
                        <div className='SummaryPoint'>
                            <span className='SummaryLabel font-600'>
                                Insurance Carrier
                            </span>
                            <span className='SummaryValue InsuranceLogo'>
                                {insurerLogo}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProductDescription;