import React, { useEffect, useState } from 'react';
import HEBlogo from '../ui/logos/PNGs/CHIPlogo.png'
import Photo from '../HomePage/images/offer-screen-image.png';
import validator from 'validator';
import { Button, Dialog, FormGroup, HTMLSelect, InputGroup, TextArea, Tooltip } from '@blueprintjs/core';
import { useSearchParams } from 'react-router-dom';
import { dollars } from '../../utils/formatter';
import { PopupModal } from 'react-calendly';
import { useMutation } from '@apollo/client';
import REQUEST_EMAIL from './mutations/requestEmail';
import ReactGA from 'react-ga4';
import './OfferPage.css';
import hpToaster from '../ui/Toaster/Toaster';
import PositionedIcon from '../ui/PositionedIcon/PositionedIcon';

const planningStageOptions = [
    `Select...`,
    `This is something I'm interested in now`,
    `This is something I will explore in the next few years`,
    `This could be relevant at some point in the future`
]

const useOfFundsOptions = [
    `Select...`,
    `As extra spending money to enjoy my retirement`,
    `To travel more`,
    `To keep up with living expenses`,
    `To worry less about outliving my savings`,
    `To feel generally less financially stressed`,
    `To fund medical expenses`,
    `To fund additional home care needs`,
    `Other`
]

const rmTooltipContent = `
    A reverse mortgage is a loan secured against the value of your home. It lets you unlock the value in your home without having to sell or move away. The money you receive is tax-free and yours to use as you wish. The loan balance is repaid when you decide to sell your home.
`

const OfferPage = ({

}) => {

    const [searchParams, setSearchParams] = useSearchParams();
    const postalCode = searchParams.get('pc');
    console.log('postalCode', postalCode);
    const monthlyIncome = Math.floor(searchParams.get('m')/100)*100;
    const reverseMortgage = Math.round(searchParams.get('rm'));
    const age = Number(searchParams.get('a'));
    const homeVal = Math.round(searchParams.get('h'));

    useEffect(() => {
        ReactGA.send({ 
            hitType: "pageview", 
            page: `${window.location.pathname+window.location.search}`, 
            title: "Home" 
        });
    }, [])

    const formattedMonthlyIncome = dollars(monthlyIncome);
    const formattedRM = dollars(reverseMortgage);

    const [planningStage, setPlanningStage] = useState('Select...');
    const onChangePlanningStage = (e) => setPlanningStage(e.currentTarget.value);

    const [useOfFunds, setUseOfFunds] = useState('Select...');
    const onChangeUseOfFunds = (e) => setUseOfFunds(e.currentTarget.value);

    const [otherInfo, setOtherInfo] = useState('');
    const onChangeOtherInfo = (e) => setOtherInfo(e.target.value);

    const [email, setEmail] = useState('');
    const onChangeEmail = (e) => setEmail(e.target.value);

    const [calendlyOpen, toggleCalendly] = useState(false);
    const onOpenCalendly = () => {
        ReactGA.event('HP_open_scheduler', {
            monthlyIncome: monthlyIncome,
            chip: reverseMortgage
        })
        toggleCalendly(true);
    }
    const onCloseCalendly = () => toggleCalendly(false);

    const [emailModalOpen, toggleEmailModal] = useState(false);
    const openEmailModal = () => {
        ReactGA.event('HP_open_email_modal', {
            monthlyIncome: monthlyIncome,
            chip: reverseMortgage
        })
        toggleEmailModal(true);
    }
    const closeEmailModal = () => toggleEmailModal(false);

    const [requestEmail, { loading, error, data }] = useMutation(REQUEST_EMAIL, {
        onCompleted: () => {
            console.log('requested')
            closeEmailModal();
            setEmail('');
            hpToaster.show({
                message: `Thank you! we will email you with your report shortly`,
            })
        }
    });

    const onRequestEmail = () => {
        ReactGA.event('HP_request_email', {
            monthlyIncome: monthlyIncome,
            chip: reverseMortgage,
            planningStage: planningStage,
            useOfFunds: useOfFunds
        })
        var callback = () => { return };
        ReactGA.event('conversion', {
            'send_to': 'AW-11380432717/ES55COaFqvEYEM2-zrIq',
            'event_callback': callback
        });
        requestEmail({
            variables: {
                input: {
                    email: email,
                    planningStage: planningStage,
                    useOfFunds: useOfFunds,
                    otherInfo: otherInfo,
                    income: monthlyIncome,
                    rm: reverseMortgage,
                    age: Number(age),
                    postalCode: postalCode,
                    homeVal: homeVal,
                    utm_source: window.localStorage.getItem('utm_source')
                }
            }
        });
        
    }

    const calendlyPrefill = {
        customAnswers: {
          a1: planningStage,
          a2: useOfFunds
        }
    }

    const planningStageOptionSet = planningStageOptions.map((string, i) => {
        return <option value={string} key={i}>{string === `` ? 'Select...' : string}</option>;
    })

    const useOfFundsOptionSet = useOfFundsOptions.map((string, i) => {
        return <option value={string} key={i}>{string === `` ? 'Select...' : string}</option>;
    })

    return (
        <div className='HomePage OfferPage page'>
            <h1>You could qualify for <br className='offer-break'/>up to <span className='secondary-accent'>{formattedMonthlyIncome} per month for life</span></h1>
            <div className='OfferPageContent'>
                <div className='TextContent'>
                    <div className='OfferExplainer'>
                        <h3 className='margin-top-0 three-quarter-opacity'>
                            Through a {formattedRM} <Tooltip
                                    minimal
                                    placement='bottom'
                                    popoverClassName='RMtooltip'
                                    content={(
                                        <div>
                                            <p>{rmTooltipContent}</p>
                                        </div>
                                    )}
                                >
                                    <span className='TooltipTarget'> reverse mortgage <PositionedIcon 
                                        icon='info-sign'
                                        color='#999'
                                        top={-6} 
                                        size={18}
                                    /></span>
                            </Tooltip> with no monthly payments and no need to leave your home
                        </h3>
                    </div>
                    <div className='PoweredByContainer margin-bottom-24'>
                        <p className='text-14 margin-bottom-0'>Powered by a name you trust</p>
                        <img src={HEBlogo} alt='HomeEquity Bank Logo' className='HEBlogo'/>
                    </div>
                    <div className='FormContainer'>
                        <div className='margin-bottom-8'>
                            <Button
                                intent='primary'
                                text='Email me my report'
                                fill
                                onClick={openEmailModal}
                            />
                        </div>
                        <div className='margin-top-16'/>
                        <p>Or <span className='LinkBtn' onClick={onOpenCalendly}>request a call to learn more</span></p>
                        <PopupModal
                            open={calendlyOpen}
                            onModalClose={onCloseCalendly}
                            url='https://calendly.com/homepensionpilot/homepension-chat'
                            rootElement={document.getElementById("root")}
                            text="Click here to schedule!"
                            prefill={calendlyPrefill}
                            textColor="#ffffff"
                            color="#00a2ff"
                        />
                        <Dialog
                            isOpen={emailModalOpen}
                            onClose={closeEmailModal}
                        >
                            <h2 className='center font-500 margin-bottom-4'>Get your HomePension report</h2>
                            <h3 className='margin-top-0 three-quarter-opacity margin-bottom-32'>We will send you your report within one business day</h3>
                            <form
                                onSubmit={onRequestEmail}
                            >
                                <FormGroup
                                    label='Enter your email'
                                    className='margin-bottom-32'
                                >
                                    <InputGroup
                                        type='email'
                                        value={email}
                                        onChange={onChangeEmail}
                                    />
                                </FormGroup>
                                <FormGroup
                                    label='Where are you in your retirement planning?'
                                    labelFor='planning-stage'
                                    className='margin-bottom-32'
                                >
                                    <HTMLSelect
                                        iconName='caret-down'
                                        value={planningStage}
                                        onChange={onChangePlanningStage}
                                        fill
                                    >
                                        {planningStageOptionSet}
                                    </HTMLSelect>
                                </FormGroup>
                                
                                <FormGroup
                                    label='What would you use this income for?'
                                    labelFor='use-of-funds'
                                    className='margin-bottom-32'
                                >
                                    <HTMLSelect
                                        iconName='caret-down'
                                        fill
                                        value={useOfFunds}
                                        onChange={onChangeUseOfFunds}
                                    >
                                        {useOfFundsOptionSet}
                                    </HTMLSelect>
                                </FormGroup>

                                {useOfFunds === 'Other' && (
                                    <FormGroup
                                        label='Tell us more'
                                        className='margin-bottom-24'
                                    >
                                        <TextArea fill 
                                            onChange={onChangeOtherInfo}
                                        />
                                    </FormGroup>
                                )}
                                <Button 
                                    type='submit'
                                    intent='primary'
                                    text='Submit'
                                    disabled={!validator.isEmail(email) || useOfFunds === 'Select...' || planningStage === 'Select...'}
                                    onClick={onRequestEmail}
                                    loading={loading}
                                />
                            </form>
                        </Dialog>
                    </div>
                </div>
                <div className='OfferPhoto'>
                    <img src={Photo} />
                </div>
            </div>
        </div>
    )

}

export default OfferPage;