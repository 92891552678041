import React from 'react';

const HomePensionLogo = ({
    width,
    fill = '#000'
}) => {

    const height = width * 41/220;

    return (
        <svg width={width} height={height} viewBox="0 0 220 41" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5 37V13H10.0742V22.9023H20.375V13H25.4375V37H20.375V27.0859H10.0742V37H5Z" fill={fill}/>
            <path d="M36.6702 37.3516C34.8498 37.3516 33.2756 36.9648 31.9475 36.1914C30.6272 35.4102 29.6077 34.3242 28.8889 32.9336C28.1702 31.5352 27.8108 29.9141 27.8108 28.0703C27.8108 26.2109 28.1702 24.5859 28.8889 23.1953C29.6077 21.7969 30.6272 20.7109 31.9475 19.9375C33.2756 19.1563 34.8498 18.7656 36.6702 18.7656C38.4905 18.7656 40.0608 19.1563 41.3811 19.9375C42.7092 20.7109 43.7327 21.7969 44.4514 23.1953C45.1702 24.5859 45.5295 26.2109 45.5295 28.0703C45.5295 29.9141 45.1702 31.5352 44.4514 32.9336C43.7327 34.3242 42.7092 35.4102 41.3811 36.1914C40.0608 36.9648 38.4905 37.3516 36.6702 37.3516ZM36.6936 33.4844C37.5217 33.4844 38.2131 33.25 38.7678 32.7813C39.3225 32.3047 39.7405 31.6563 40.0217 30.8359C40.3108 30.0156 40.4553 29.082 40.4553 28.0352C40.4553 26.9883 40.3108 26.0547 40.0217 25.2344C39.7405 24.4141 39.3225 23.7656 38.7678 23.2891C38.2131 22.8125 37.5217 22.5742 36.6936 22.5742C35.8577 22.5742 35.1545 22.8125 34.5842 23.2891C34.0217 23.7656 33.5959 24.4141 33.3069 25.2344C33.0256 26.0547 32.885 26.9883 32.885 28.0352C32.885 29.082 33.0256 30.0156 33.3069 30.8359C33.5959 31.6563 34.0217 32.3047 34.5842 32.7813C35.1545 33.25 35.8577 33.4844 36.6936 33.4844Z" fill={fill}/>
            <path d="M47.7856 37V19H52.5434V22.1758H52.7544C53.1294 21.1211 53.7544 20.2891 54.6294 19.6797C55.5044 19.0703 56.5512 18.7656 57.77 18.7656C59.0044 18.7656 60.0552 19.0742 60.9223 19.6914C61.7895 20.3008 62.3677 21.1289 62.6567 22.1758H62.8442C63.2114 21.1445 63.8755 20.3203 64.8364 19.7031C65.8052 19.0781 66.9497 18.7656 68.27 18.7656C69.9497 18.7656 71.313 19.3008 72.3598 20.3711C73.4145 21.4336 73.9419 22.9414 73.9419 24.8945V37H68.9614V25.8789C68.9614 24.8789 68.6958 24.1289 68.1645 23.6289C67.6333 23.1289 66.9692 22.8789 66.1723 22.8789C65.2661 22.8789 64.5591 23.168 64.0513 23.7461C63.5434 24.3164 63.2895 25.0703 63.2895 26.0078V37H58.4497V25.7734C58.4497 24.8906 58.1958 24.1875 57.688 23.6641C57.188 23.1406 56.5278 22.8789 55.7075 22.8789C55.1528 22.8789 54.6528 23.0195 54.2075 23.3008C53.77 23.5742 53.4223 23.9609 53.1645 24.4609C52.9067 24.9531 52.7778 25.5313 52.7778 26.1953V37H47.7856Z" fill={fill}/>
            <path d="M85.0984 37.3516C83.2468 37.3516 81.653 36.9766 80.3171 36.2266C78.989 35.4688 77.9655 34.3984 77.2468 33.0156C76.528 31.625 76.1687 29.9805 76.1687 28.082C76.1687 26.2305 76.528 24.6055 77.2468 23.207C77.9655 21.8086 78.9773 20.7188 80.282 19.9375C81.5945 19.1563 83.1335 18.7656 84.8991 18.7656C86.0866 18.7656 87.1921 18.957 88.2155 19.3398C89.2468 19.7148 90.1452 20.2813 90.9109 21.0391C91.6843 21.7969 92.2859 22.75 92.7155 23.8984C93.1452 25.0391 93.3601 26.375 93.3601 27.9063V29.2773H78.1609V26.1836H88.6609C88.6609 25.4648 88.5046 24.8281 88.1921 24.2734C87.8796 23.7188 87.446 23.2852 86.8913 22.9727C86.3445 22.6523 85.7077 22.4922 84.9812 22.4922C84.2234 22.4922 83.5515 22.668 82.9655 23.0195C82.3874 23.3633 81.9343 23.8281 81.6062 24.4141C81.278 24.9922 81.1101 25.6367 81.1023 26.3477V29.2891C81.1023 30.1797 81.2663 30.9492 81.5945 31.5977C81.9304 32.2461 82.403 32.7461 83.0124 33.0977C83.6218 33.4492 84.3445 33.625 85.1804 33.625C85.7351 33.625 86.2429 33.5469 86.7038 33.3906C87.1648 33.2344 87.5593 33 87.8874 32.6875C88.2155 32.375 88.4655 31.9922 88.6374 31.5391L93.2546 31.8438C93.0202 32.9531 92.5398 33.9219 91.8132 34.75C91.0945 35.5703 90.1648 36.2109 89.0241 36.6719C87.8913 37.125 86.5827 37.3516 85.0984 37.3516Z" fill={fill}/>
            <path d="M95.7216 37V13H105.19C107.011 13 108.561 13.3477 109.843 14.043C111.124 14.7305 112.101 15.6875 112.772 16.9141C113.452 18.1328 113.792 19.5391 113.792 21.1328C113.792 22.7266 113.448 24.1328 112.761 25.3516C112.073 26.5703 111.077 27.5195 109.772 28.1992C108.476 28.8789 106.905 29.2188 105.061 29.2188H99.0263V25.1523H104.241C105.218 25.1523 106.022 24.9844 106.655 24.6484C107.296 24.3047 107.772 23.832 108.085 23.2305C108.405 22.6211 108.565 21.9219 108.565 21.1328C108.565 20.3359 108.405 19.6406 108.085 19.0469C107.772 18.4453 107.296 17.9805 106.655 17.6523C106.015 17.3164 105.202 17.1484 104.218 17.1484H100.796V37H95.7216Z" fill={fill}/>
            <path d="M123.917 37.3516C122.066 37.3516 120.472 36.9766 119.136 36.2266C117.808 35.4688 116.784 34.3984 116.066 33.0156C115.347 31.625 114.988 29.9805 114.988 28.082C114.988 26.2305 115.347 24.6055 116.066 23.207C116.784 21.8086 117.796 20.7188 119.101 19.9375C120.413 19.1563 121.952 18.7656 123.718 18.7656C124.905 18.7656 126.011 18.957 127.034 19.3398C128.066 19.7148 128.964 20.2813 129.73 21.0391C130.503 21.7969 131.105 22.75 131.534 23.8984C131.964 25.0391 132.179 26.375 132.179 27.9063V29.2773H116.98V26.1836H127.48C127.48 25.4648 127.323 24.8281 127.011 24.2734C126.698 23.7188 126.265 23.2852 125.71 22.9727C125.163 22.6523 124.527 22.4922 123.8 22.4922C123.042 22.4922 122.37 22.668 121.784 23.0195C121.206 23.3633 120.753 23.8281 120.425 24.4141C120.097 24.9922 119.929 25.6367 119.921 26.3477V29.2891C119.921 30.1797 120.085 30.9492 120.413 31.5977C120.749 32.2461 121.222 32.7461 121.831 33.0977C122.441 33.4492 123.163 33.625 123.999 33.625C124.554 33.625 125.062 33.5469 125.523 33.3906C125.984 33.2344 126.378 33 126.706 32.6875C127.034 32.375 127.284 31.9922 127.456 31.5391L132.073 31.8438C131.839 32.9531 131.359 33.9219 130.632 34.75C129.913 35.5703 128.984 36.2109 127.843 36.6719C126.71 37.125 125.402 37.3516 123.917 37.3516Z" fill={fill}/>
            <path d="M139.439 26.5938V37H134.447V19H139.205V22.1758H139.415C139.814 21.1289 140.482 20.3008 141.419 19.6914C142.357 19.0742 143.494 18.7656 144.83 18.7656C146.08 18.7656 147.169 19.0391 148.099 19.5859C149.029 20.1328 149.751 20.9141 150.267 21.9297C150.783 22.9375 151.04 24.1406 151.04 25.5391V37H146.048V26.4297C146.056 25.3281 145.775 24.4688 145.205 23.8516C144.634 23.2266 143.849 22.9141 142.849 22.9141C142.177 22.9141 141.583 23.0586 141.068 23.3477C140.56 23.6367 140.162 24.0586 139.872 24.6133C139.591 25.1602 139.447 25.8203 139.439 26.5938Z" fill={fill}/>
            <path d="M168.962 24.1328L164.391 24.4141C164.313 24.0234 164.145 23.6719 163.887 23.3594C163.63 23.0391 163.29 22.7852 162.868 22.5977C162.454 22.4023 161.958 22.3047 161.38 22.3047C160.606 22.3047 159.954 22.4688 159.423 22.7969C158.891 23.1172 158.626 23.5469 158.626 24.0859C158.626 24.5156 158.798 24.8789 159.141 25.1758C159.485 25.4727 160.075 25.7109 160.911 25.8906L164.169 26.5469C165.919 26.9063 167.223 27.4844 168.083 28.2813C168.942 29.0781 169.372 30.125 169.372 31.4219C169.372 32.6016 169.024 33.6367 168.329 34.5273C167.641 35.418 166.696 36.1133 165.493 36.6133C164.298 37.1055 162.919 37.3516 161.356 37.3516C158.973 37.3516 157.075 36.8555 155.661 35.8633C154.255 34.8633 153.43 33.5039 153.188 31.7852L158.098 31.5273C158.247 32.2539 158.606 32.8086 159.176 33.1914C159.747 33.5664 160.477 33.7539 161.368 33.7539C162.243 33.7539 162.946 33.5859 163.477 33.25C164.016 32.9063 164.29 32.4648 164.298 31.9258C164.29 31.4727 164.098 31.1016 163.723 30.8125C163.348 30.5156 162.77 30.2891 161.989 30.1328L158.872 29.5117C157.114 29.1602 155.805 28.5508 154.946 27.6836C154.094 26.8164 153.669 25.7109 153.669 24.3672C153.669 23.2109 153.981 22.2148 154.606 21.3789C155.239 20.543 156.126 19.8984 157.266 19.4453C158.415 18.9922 159.758 18.7656 161.298 18.7656C163.571 18.7656 165.36 19.2461 166.665 20.207C167.977 21.168 168.743 22.4766 168.962 24.1328Z" fill={fill}/>
            <path d="M171.525 37V19H176.518V37H171.525ZM174.033 16.6797C173.291 16.6797 172.654 16.4336 172.123 15.9414C171.6 15.4414 171.338 14.8438 171.338 14.1484C171.338 13.4609 171.6 12.8711 172.123 12.3789C172.654 11.8789 173.291 11.6289 174.033 11.6289C174.775 11.6289 175.408 11.8789 175.932 12.3789C176.463 12.8711 176.728 13.4609 176.728 14.1484C176.728 14.8438 176.463 15.4414 175.932 15.9414C175.408 16.4336 174.775 16.6797 174.033 16.6797Z" fill={fill}/>
            <path d="M187.659 37.3516C185.839 37.3516 184.265 36.9648 182.937 36.1914C181.616 35.4102 180.597 34.3242 179.878 32.9336C179.159 31.5352 178.8 29.9141 178.8 28.0703C178.8 26.2109 179.159 24.5859 179.878 23.1953C180.597 21.7969 181.616 20.7109 182.937 19.9375C184.265 19.1563 185.839 18.7656 187.659 18.7656C189.48 18.7656 191.05 19.1563 192.37 19.9375C193.698 20.7109 194.722 21.7969 195.441 23.1953C196.159 24.5859 196.519 26.2109 196.519 28.0703C196.519 29.9141 196.159 31.5352 195.441 32.9336C194.722 34.3242 193.698 35.4102 192.37 36.1914C191.05 36.9648 189.48 37.3516 187.659 37.3516ZM187.683 33.4844C188.511 33.4844 189.202 33.25 189.757 32.7813C190.312 32.3047 190.73 31.6563 191.011 30.8359C191.3 30.0156 191.444 29.082 191.444 28.0352C191.444 26.9883 191.3 26.0547 191.011 25.2344C190.73 24.4141 190.312 23.7656 189.757 23.2891C189.202 22.8125 188.511 22.5742 187.683 22.5742C186.847 22.5742 186.144 22.8125 185.573 23.2891C185.011 23.7656 184.585 24.4141 184.296 25.2344C184.015 26.0547 183.874 26.9883 183.874 28.0352C183.874 29.082 184.015 30.0156 184.296 30.8359C184.585 31.6563 185.011 32.3047 185.573 32.7813C186.144 33.25 186.847 33.4844 187.683 33.4844Z" fill={fill}/>
            <path d="M203.767 26.5938V37H198.775V19H203.533V22.1758H203.744C204.142 21.1289 204.81 20.3008 205.747 19.6914C206.685 19.0742 207.822 18.7656 209.158 18.7656C210.408 18.7656 211.497 19.0391 212.427 19.5859C213.357 20.1328 214.079 20.9141 214.595 21.9297C215.111 22.9375 215.369 24.1406 215.369 25.5391V37H210.376V26.4297C210.384 25.3281 210.103 24.4688 209.533 23.8516C208.962 23.2266 208.177 22.9141 207.177 22.9141C206.505 22.9141 205.912 23.0586 205.396 23.3477C204.888 23.6367 204.49 24.0586 204.201 24.6133C203.919 25.1602 203.775 25.8203 203.767 26.5938Z" fill={fill}/>
            <path d="M52.9115 4L74.2448 14.6667H31.5781L52.9115 4Z" fill={fill}/>
        </svg>
    )
}

export default HomePensionLogo;