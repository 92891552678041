import { Icon } from '@blueprintjs/core';
import React from 'react';

const PositionedIcon = ({
    top = 0,
    left = 0,
    marginLeft = 0,
    size = 12,
    color = `#000`,
    icon
}) => {

    return (
        <Icon
            icon={icon}
            color={color}
            size={size}
            style={{
                position: 'relative',
                marginLeft: marginLeft,
                left: left,
                top: top,
            }}
        />
    )
}

export default PositionedIcon;