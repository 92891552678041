import React from 'react';
import { dollars } from '../../../utils/formatter';
import BarChart from '../../Calculator/VisualizePlan/BarChart/BarChart';
import { Divider } from '@blueprintjs/core';

const ChartBox = ({
    yearsToLive,
    lifeExpectancy,
    yearsInHome,
    homeGrowthRate,
    mortgageRate,
    mortgage,
    homeVal,
    monthlyIncome,
    remainingEquity,
    mortgageTerm,
    renewalRate
}) => {

    const incomeSeries = [...Array(Number(yearsToLive)).keys()].map(i => monthlyIncome * 12 * (i+1));
    const totalIncome = monthlyIncome*12*yearsToLive
    const formattedTotalIncome = dollars(totalIncome);

    const homeValSeries = [...Array(Number(yearsInHome)).keys()].map(i => homeVal * (homeGrowthRate**(i)));
    const mortgageSeries = [...Array(Number(yearsInHome)).keys()].map(i => {

        let compoundedMortgage;
        if (i < mortgageTerm) {
            compoundedMortgage = mortgage * (mortgageRate**(i+1));
        }
        if (i >= mortgageTerm) {
            const mortgageToTerm = mortgage + (mortgage * (mortgageRate**(mortgageTerm)) - mortgage);
            compoundedMortgage = mortgageToTerm * (renewalRate**(i+1-mortgageTerm));
        }
        let regulatedMortgage = compoundedMortgage > homeValSeries[i] ? homeValSeries[i] : compoundedMortgage;

        return regulatedMortgage;

    });

    const yAxisExtent = Math.max(...homeValSeries);

    return (
        <div className='PrintBoxSection border'>
            <h3 className='margin-top-4'>
                By age {lifeExpectancy}, you could receive {formattedTotalIncome} in monthly income
            </h3>
            <BarChart
                title={''}
                rangeYears={yearsToLive}
                primaryBarValues={incomeSeries}
                yAxisExtent={yAxisExtent}
                primaryBarLegend='Total income received'
                heightMultiple={50}
            />
        </div>
    )
}

export default ChartBox;