import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './bp5-overrides.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
// import smoothscroll from 'smoothscroll-polyfill';
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css'


import Hotjar from '@hotjar/browser';
import ReactGA from 'react-ga4';

import { BrowserRouter as Router } from 'react-router-dom';

import { ApolloClient, ApolloProvider, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

import { FocusStyleManager } from '@blueprintjs/core';
import { getToken, localStorageKey } from './utils/authUtils';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';

// var isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && window['safari'].pushNotification));

// if (isSafari) {
//   smoothscroll.polyfill();
// }

// hotjar
const siteId = 3692325;
const hotjarVersion = 6;
Hotjar.init(siteId, hotjarVersion);

// accessibility
FocusStyleManager.onlyShowFocusOnTabs();

const httpLink = createHttpLink({
  uri: "https://1xnpthfo85.execute-api.us-east-1.amazonaws.com/prod/graphql" 
});

ReactGA.initialize('G-B2600WCM57');

// DEV API https://glf8gsbpoc.execute-api.us-east-1.amazonaws.com/dev/graphql
// PROD API https://1xnpthfo85.execute-api.us-east-1.amazonaws.com/prod/graphql

const authLink = setContext((_, { headers }) => {
  const token = getToken();

  const headerSet = {
    ...headers,
    authorization: token ? `Bearer ${token}` : "",
    // userId: window.localStorage.getItem('homepension-user')
  }

  return { 
    headers: headerSet
  }
})

export const apolloCache = new InMemoryCache();

export const apolloClient = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: apolloCache
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
      <ApolloProvider client={apolloClient}>
        <App />
      </ApolloProvider>
    </Router>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
