import { Divider } from '@blueprintjs/core';
import React from 'react';
import { dollars } from '../../../utils/formatter';

const ProjectionTable = ({
    homeVal,
    futureHomeVal,
    mortgage,
    futureMortgage,
    existingMortgage,
    currentRemainingEquity,
    futureRemainingEquity,
    futureInterest,
    incomeAfterYearsInHome
}) => {

    const formattedExistingMortgage = existingMortgage ? dollars(existingMortgage) : null;

    return (
        <div className='PrintBoxSection border'>
            <h3 className='margin-top-4 margin-bottom-0'>
                Even after 10 years you could have {futureRemainingEquity} remaining in home equity
            </h3>
            <div className='Table'>
                <div className='TableColumn labels'>
                    <span className='EmptyCell'> </span>
                    <span className='LabelCell'>Home Value</span>
                    {existingMortgage > 0 && <span className='LabelCell'>Existing Mortgage</span>}
                    <span className='LabelCell'>Reverse Mortgage</span>
                    <span className='LabelCell'>Interest</span>
                </div>
                <div className='TableColumn right-align content'>
                    <span className='LabelCell'>Today</span>
                    <span className='TableCell'>{homeVal}</span>
                    {existingMortgage > 0 && <span className='TableCell'><s>{formattedExistingMortgage}</s> $0</span>}
                    <span className='TableCell'>{mortgage}</span>
                    <span className='TableCell'>$0</span>
                </div>
                <div className='TableColumn right-align content'>
                    <span className='LabelCell'>In 10 Years</span>
                    <span className='TableCell'>{futureHomeVal}</span>
                    {existingMortgage > 0 && <span className='TableCell'>$0</span>}
                    <span className='TableCell'>{mortgage}</span>
                    <span className='TableCell'>{futureInterest}</span>
                </div>
            </div>
            <div className='margin-top-8 margin-bottom-8'>
                <Divider />
            </div>
            <div className='Table'>
                <div className='TableColumn labels'>
                    <span className='LabelCell'>Remaining Home Equity</span>
                    <span className='LabelCell'>Total income received</span>
                </div>
                <div className='TableColumn right-align content'>
                    <span className='TableCell'>{currentRemainingEquity}</span>
                    <span className='TableCell'>$0</span>
                </div>
                <div className='TableColumn right-align content'>
                    <span className='TableCell'>{futureRemainingEquity}</span>
                    <span className='TableCell'>{incomeAfterYearsInHome}</span>
                </div>
            </div>
        </div>
    )
}

export default ProjectionTable;