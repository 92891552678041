import { gql } from "@apollo/client";

const QUOTE = gql`
    query Quote(
        $age1: Int
        $homeVal: Int
        $postCode: String
        $city: String
        $attributionSource: String
    ) {
        quote(
            age1: $age1
            gender1: "Male"
            homeVal: $homeVal
            existingMortgage: 0
            postCode: $postCode
            homeType: "A1 - Single Family Dwelling"
            city: $city
            user: "CONSUMER"
            attributionSource: $attributionSource
        ) {
            chipQuoteMax
            quoteType
            annuityOffers {
                insurer
                monthlyIncome
            }
            age1
            gender1
            age2
            gender2
            homeVal
            existingMortgage
            postCode
            error
        }
    }
`

export default QUOTE;