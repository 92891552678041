import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import HomePensionLogo from '../ui/logos/HomePensionLogo/HomePensionLogo';
import { Divider } from '@blueprintjs/core';
import { dollars } from '../../utils/formatter';
import HEBlogo from '../ui/logos/PNGs/HomeEquity_Bank_HomeEquity_Bank_Launches_Campaign_Highlighting_H.jpg';

import './QuoteDocument.css';
import { getInsurerLogo } from '../Calculator/QuoteInfo/InsurerCard/InsurerCard';
import BenefitSummary from './BenefitSummary/BenefitSummary';
import PersonalizedSummary from './PersonalizedSummary/PersonalizedSummary';
import ProductDescription from './ProductDescription/ProductDescription';
import ProjectionTable from './ProjectionTable/ProjectionTable';
import ChartBox from './ChartBox/ChartBox';
import QuoteDocHeader from './Header/QuoteDocHeader';
import QuoteDocFooter from './QuoteDocFooter/QuoteDocFooter';
import TimelineTable from './TimelineTable/TimelineTable';
import TimelinePages from './TimelinePage/TimelinePages';


const mockData = {
    quote: {
        chipQuoteMax: 342500,
        annuityOffers: [
            {
                insurer: 'RBC Life Insurance Company',
                monthlyIncome: 2013
            },
            {
                insurer: 'Desjardins Financial Security',
                monthlyIncome: 1999
            },
            {
                insurer: 'Equitable Life',
                monthlyIncome: 1990
            },
            {
                insurer: 'BMO Insurance',
                monthlyIncome: 1963
            }
        ]
    }
}

const QuoteDocument = ({

}) => {

    const MORTGAGE_RATE = 1.0819;
    const HOME_GROWTH_RATE = 1.03;
    const LIFE_EXPECTANCY = 95;

    const [searchParams, setSearchParams] = useSearchParams();
    const age = searchParams.get('age');
    const homeVal = searchParams.get('homeVal');
    const monthlyIncome = searchParams.get('monthlyIncome');
    const rm = searchParams.get('rm');

    const yearsToLive = LIFE_EXPECTANCY - age;

    const formattedMonthlyIncome = dollars(monthlyIncome);

    const formattedHomeVal = dollars(homeVal);

    const formattedMortgage = dollars(rm);
    const futureMortgageVal = rm * (MORTGAGE_RATE**10)
    const formattedFutureMortgageVal = dollars(futureMortgageVal);

    const futureHomeVal = homeVal * HOME_GROWTH_RATE**10;
    const formattedFutureHomeVal = dollars(futureHomeVal);
  
    const currentRemainingEquity = dollars(homeVal - rm);
    const remainingEquity = futureHomeVal - futureMortgageVal > 0 ? futureHomeVal - futureMortgageVal : 0;
    const formattedRemainingEquity = dollars(remainingEquity);

    const formattedFutureInterest = futureMortgageVal > futureHomeVal ? dollars(futureHomeVal - rm) : dollars(futureMortgageVal - rm);


    return (
        <div className='QuoteDocument'>
            <div className='PrintPage page-break-after'>
                <QuoteDocHeader />
                <h1 className='PrintHeroMessage'>
                    <span className='accent-text'>
                    </span>You could get {formattedMonthlyIncome}/month for life
                </h1>
                <BenefitSummary />
                <Divider />
                <div className='Columns'>
                    <div className='ColumnA'>
                        <PersonalizedSummary 
                            monthlyIncome={monthlyIncome}
                            homeVal={homeVal}
                            mortgage={rm}
                            mortgageRate={MORTGAGE_RATE}
                        />
                    </div>
                    <div className='ColumnB'>
                        <ProductDescription insurer={'Sun Life Assurance Company of Canada'} />
                    </div>
                </div>
                <div 
                    className='Columns margin-top-0'
                    style={{ paddingTop: 4 }}
                >
                    <div className='ColumnA'>
                        <ProjectionTable 
                            homeVal={formattedHomeVal}
                            futureHomeVal={formattedFutureHomeVal}
                            mortgage={formattedMortgage}
                            futureMortgage={formattedFutureMortgageVal}
                            currentRemainingEquity={currentRemainingEquity}
                            futureRemainingEquity={formattedRemainingEquity}
                            futureInterest={formattedFutureInterest}
                        />
                    </div>
                    <div className='ColumnB'>
                        <ChartBox 
                            yearsToLive={yearsToLive}
                            yearsInHome={yearsToLive}
                            lifeExpectancy={LIFE_EXPECTANCY}
                            monthlyIncome={monthlyIncome}
                            homeGrowthRate={HOME_GROWTH_RATE}
                            mortgageRate={MORTGAGE_RATE}
                            mortgage={rm}
                            homeVal={homeVal}
                            remainingEquity={formattedRemainingEquity}
                            mortgageTerm={5}
                            renewalRate={MORTGAGE_RATE}
                        />
                        <QuoteDocFooter  />
                    </div>
                </div>
                <div className='margin-top-16 margin-bottom-16'>
                    <Divider />
                </div>
                <p 
                    className='margin-top-0 margin-bottom-0'
                    style={{ 
                        fontSize: 9
                    }}
                >
                    1. Estimate only: exact amounts may change upon home Appraisal
                </p>
                <p 
                    className='margin-top-4 margin-bottom-0'
                    style={{ 
                        fontSize: 9
                    }}
                >
                    Annuity quote data provided by CANNEX
                </p>
            </div>
        </div>
    )
}

export default QuoteDocument;