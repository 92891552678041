import { gql } from "@apollo/client";

const QUOTE = gql`
    query Quote(
        $age1: Int
        $gender1: String
        $age2: Int
        $gender2: String
        $homeVal: Int
        $existingMortgage: Int
        $postCode: String
        $homeType: String
        $city: String
        $user: String
    ) {
        quote(
            age1: $age1
            gender1: $gender1
            age2: $age2
            gender2: $gender2
            homeVal: $homeVal
            existingMortgage: $existingMortgage
            postCode: $postCode
            homeType: $homeType
            city: $city
            user: $user
        ) {
            chipQuoteMax
            quoteType
            annuityOffers {
                insurer
                monthlyIncome
            }
            age1
            gender1
            age2
            gender2
            homeVal
            existingMortgage
            postCode
            error
        }
    }
`

export default QUOTE;