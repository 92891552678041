import logo from './logo.svg';
import './App.css';
import Calculator from './components/Calculator/Calculator';
import TopNav from './components/TopNav/TopNav';
import { Route, Routes } from 'react-router';
import HomePage from './components/HomePage/HomePage';
import QuoteDocument from './components/QuoteDocument/QuoteDocument';
import LoginGate from './components/LoginGate/LoginGate';
import FAQ from './components/FAQ/FAQ';
import OfferPage from './components/OfferPage/OfferPage';
import Footer from './components/ui/Footer/Footer';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

function App() {

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const utm_source = searchParams.get('utm_source');
    window.localStorage.setItem('utm_source', utm_source);
  }, [])

  return (
    <div className="App">
      <ScrollToTop />
      <Routes>
        <Route path='/' element={(
          <>
            <TopNav />
            <HomePage />
            <Footer />

          </>
        )}/>
        <Route path='/offer' element={(
          <>
            <TopNav />
            <OfferPage />
            <Footer />

          </>
        )}/>
        <Route path='/print' element={(
          <QuoteDocument />
        )}/>
        <Route path='/faq' element={(
          <>
            <TopNav />
            <FAQ />
          </>
        )} />
        <Route path='/quote' element={<QuoteDocument />} />
      </Routes>
    </div>
  );
}

export default App;
